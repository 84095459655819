.left_process-container {
    .ant-tabs-tab {
        color : white;
        padding: 4px 24px !important;
    }
    .ant-tabs-tab-btn {
        color : white;
    }
    .ant-tabs-tab-active {
        background: white !important;
        width: 95%;
        border-radius: 5px;
       
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #CA4A04 !important;
    }
    .ant-tabs {
        color : white;
    }
    .tabFont {
        font-size: 13px;
    }
    .trialTitle {
        text-align: left;
        font-size:  18px ; 
        color: #FFFFFF;
        span span {
            &:nth-child(1) {
              pointer-events: none;
            }
        }
    }
    .scenario-name {
        font-size:  14px; 
        color: #999999;
        opacity: 1;
    }
    .tab-container {
        padding-bottom: 5%;
        margin: 5%; 
        .name {
            span span span{
                &:nth-child(1) {
                  pointer-events: none;
                }
            }
        }
    }
    .scenario-name-container {
        border-bottom: 1px solid white !important;
        padding-bottom: 12px;
    }
    .ie-schedule-selection {
        cursor: pointer;
        // z-index: 9999;
    }
    .action-title {
        z-index: 9999;
        position: absolute;
        bottom: 10px;
        font-size: 12px;
        align-items: center;
    }
    .icon-holder {
        height: 64%;
        vertical-align: middle;
        display: inline-block;
    }
    .schedule-events {
        padding-top: 10%
    }
    .left-panel-row {
        justify-content: center;
    }
    .ant-tabs-ink-bar {
        background: none !important;
    }
    .ant-tabs-content-holder {
        border-left: none;
    }
    .schedule-active {
        color: #CA4A04;
        background: white;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
        align-items: center;
        padding: 5px;
    }
    
}