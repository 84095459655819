@import "../../styles/variables.scss";

.selected-item_soa {
  font-weight: bold;
  background-color: #e6f7ff;
}
#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-popover-placement-bottom.visit-tooltip, .ant-popover-placement-bottomLeft.visit-tooltip, .ant-popover-placement-bottomRight.visit-tooltip {
  width: 0% !important;

  .ant-popover-arrow {
    display: none;
  }
}

.ant-select-item-option-content {
  overflow: initial;
}
.ant-dropdown-menu { 
// width: 107px;
height: auto; 
text-overflow: ellipsis;
overflow: auto !important;
margin: 0 auto;
overflow: scroll;
}

.soaDetailModal {
  display: flex;
  align-items: center; 
  justify-content: center;
}

.ant-select-dropdown {
  width: 170px !important;
}

.event-list-container {
  border: 1px solid #eeeeee;
  font-size: 12px;
  // padding-right: 2%;

.ant-input-affix-wrapper > input.ant-input {
    font-size: 15px;
}
  .hidde{
    display: none;
  }

  .clearfix::after {
       content: '';
       display: block;
       clear: both;
   } 

  .ant-collapse {
    border: none;
    display: flex;
    flex-direction: column; 
    .collapse-container {
      border: none; 
      position: relative;
      flex: 1; 
      background-color: #fff;
      .ant-collapse-header {
         border: none;
         position: sticky;
         left: 0px;
         top: 0px;
         z-index: 99;
         background-color: #fff;
         width: 689px;
         .event-panel-head {
           
          .event-title { 
          }
          
          
        }
      }
      .ant-collapse-content {
        position: relative;
        border-top:0px solid #eeeeee;
      }
    }  
  }
  
  .container-top {
    padding: 10px;
    display: flex;
    border-bottom: 1px solid #eeeeee;

    .scenarios-heading-left { 
      padding: 10px;
      flex: 50%;
      max-width: calc(50%);
      // padding-left: 25px;

      span {
        color: #333333;
        font-size: 26px;
      }

      p {
        font-size:  16px;
      }
    }
    
    .scenarios-heading-right { 
      padding: 10px;
      flex: 50%;
      max-width: calc(50%);
    
      .btn-scenarios-container-header {
        float: right;
    
        .soa-btn {
            background-color: #FFFFFF;
            border-radius: 2px;
            color: #ca4a04;
            border: none;
            margin: 10px;
    
            .soa-activity-icon {
                padding-bottom: 4px;
                vertical-align: middle;
            }
        }
    
        .save_soa_btn {
            margin: 10px;
        }
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      color: #333;
      // font-weight: 500;
      line-height: 35px;
    }
    .none-click {
      font-size: 14px!important;
      color: #333;
      pointer-events: none;
      input{
          color: #333;
      }
    }
    .saveSOE {
      color: #ffffff;
      font-size: 14px;
    }
    .ant-btn.ant-btn-primary[disabled], 
    .ant-btn.ant-btn-primary[disabled]:hover, 
    .ant-btn.ant-btn-primary[disabled]:focus, 
    .ant-btn.ant-btn-primary[disabled]:active {
      color: rgba(0, 0, 0, 0.25) !important;
      background: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }
  .e-row {
    border-bottom: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    background-color: #fff;
  }
  .td {
    padding-left: 10px;
    border-right: 1px solid #eeeeee;
    padding: 5px 0;
  }
  .event-dashboard {
    .dashboard-head {
      display: flex;
    }
    .event-list-head {
      flex: 1;
      .head-row {
        display: flex;
        background-color: rgb(255, 255, 255);
        .head-bottom-container{
          display: flex;
          flex:1;
          .row-title{
            font-weight: 500;
            min-width: 50px;
            border-bottom: 2px solid #eee;
          }
          .e-row.number{
            display: flex;
            width: 100%;
            .column.td.row-title{
              
            };
          }
          
        }
        .week-row-wrapper{
          flex:1;
          border:none;

          .weeks-container{
            display: flex;
            width: 100%;
            height: 34px;
            border:none;
            box-sizing: border-box;
            .num-cell{
              flex:1;
              min-width: 41px;
            }
            .cell-input {
              box-sizing: border-box;
              border: none;
              border-right: 1px solid #eeeeee;
              &:last-child {
                border-right: 0px solid transparent;
              }
            }
          }
          .column-action-container{
            display: flex;
            .td{
              height: 42px;
              border-right: 1px solid #eeeeee;
              padding: 5px 0;
              text-align: center;
              background-color: #f5f5f5;
            }
            .num-cell{
              flex:1;
            }
          }

        }

        div{
          font-size: 14px;
        }
        &:last-child {
          border-bottom: 1px solid #eeeeee;
        }
        .e-row {
          border-right: 1px solid #eeeeee;
        }
        .sortable-item {
          display: flex;
          align-items: center;
          .sort-icon-wrapper {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .colunm-row {
        font-weight: 500;
        // flex: 1.5;
        .f-1-small {
          // flex: 0.5;
          padding-left: 10px;
        }
        .f-2-small {
          // flex: 0.5;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        .f-1 {
          // flex: 1;
        }
        .f-2-new {
          // flex: 2.4;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        .f-3 {
          // flex: 2.4;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
      }
      .legend-wrapper {
        display: flex;
        align-items: center;
        margin-right: 10px;
        .legend-box {
          width: 6px;
          height: 18px;
          margin-right: 5px;
          background-color: pink;
        }
        .legend-title {
          font-size: 12px;
        }
      }

    }
    .event-list {
      flex: 1;
      border-right: 1px solid #eeeeee;
    }

    .week-row {
      display: flex;
      border-right: 1px solid #eeeeee;
      div {
        border-right: 1px solid #eeeeee;
        &:last-child {
          border-right: none;
        }
      }
    }
    .visit-row {
      display: flex;
      flex: 1;
      border-right: 1px solid #eeeeee;
      div {
        flex: 1;
        padding: 5px 0px;
        border-right: 1px solid #eeeeee;
        &:last-child {
          border-right: none;
        }
      }
      .colunm.td {
        font-weight: 500;
        min-width: 50px;
      }
    }
    .status-row.e-row {
      display: flex;
      flex: 1;
      padding: 0;
      .colunm.td {
        background: #f5f5f5;
      }
    }
    .e-row.number{
      display: flex;
      .td {    
        text-align: center;
        //padding: 0 2px !important;
      }
      .num-cell{
        flex:1;
        min-width: 41px;
        .notes-message-icon  { 
          color: #ca4a04 !important;
        }
      }

    } 
    .event-item {
      display: flex;
      position:relative;
      height: 42px;
      // width: 100%;

      .events-wrapper {
        position: relative;
        top:0;
        left:0;
        display: flex;
        // flex: 1.26;
        font-size: 14px;
        // width: 634px;
        height: 42px;

        .custom-event{
          padding:0 5px;
          display: flex;
          align-items: center;
          
          .ant-input{
            margin-left: 5px;
          }

          .anticon{
            color:$theme-color;
            cursor: pointer;
          }
        }

        .my-event-td {
          width: 230px;
          // height: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 3px;
          box-sizing: border-box;
          line-height: 32px;
          height: 42px;
        }
        .frequency-td {
          width: 92.8px;
        }
        .visit-td {
          width: 65.8px;
        }
        .endpoint-td{
          width: 104px;
          // height: 100%;
        }

        .cost-td{
          display: flex;
          align-items: center;
          width: 110.8px;
          // height: 100%;

          .ant-input{
            margin-right: 2px;
          }
        }

        // Brought over from v1.1 - Hide the icon for input number type
        // .cost-td input::-webkit-outer-spin-button,
        // .cost-td input::-webkit-inner-spin-button {
        //     -webkit-appearance: none;
        // }
        // .cost-td input[type='number']{
        //     -moz-appearance: textfield;
        // }

        .visits-td {
          width: 56.4px;
          // height: 100%;

        }

        .screening-frequency-td {
          width: 85.4px
        }

        div {
          // flex: 1;
          padding-left: 10px;
          .endpoint-span {
            max-height: 25px;
            width: 100%;
            word-break: break-all;
            height: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: clip;
          }
          &.endpoint-td {
            overflow: hidden;
            padding: 0 2px;
            display: flex;
            align-items: center;
            .ant-select {
              padding-left: 0;
            }
          }
        }
        .f-1-small {
          // flex: 0.28;
        }
        .f-2-small {
          // flex: 0.62;
        }
        .f-1 {
          // flex: 1;
        }
        .f-2 {
          // flex: 1.44;
        }
        .f-3 {
          // flex: 1.36;
        }
      }

      .status-row {
        // flex: 0.84;
        display: flex;
        // padding-left:634px;
        // width:100%;

        .td {
          flex: 1;
          text-align: center;
          min-width: 41px;
          &:last-child {
            border-right: none;
          }
        }

        // .edit-soa-details {
        //   color: #CA4A04;
        //   font-size: 14px;
        // }

        .colunm.td {
          min-width: 50px;
        }
        .incon-wrapper {
          display: inline-block;
          cursor: pointer;
        }
        .viewOnly{
          cursor: default;
        }
      }

      .edit-soa-details {
        color: #CA4A04;
        font-size: 14px;
        padding-top: 10px;
        display: block;
      }

    }
    .ant-collapse-header {
      display: flex;
      align-items: center;
      height: 35px;
    }
    .event-panel-head {
      display: flex;
      flex: 1;
      border: none;
      background-color: #fff;
      div {
        // flex: 1;
      }
      .event-title {
        // width:612px;
        // flex: 1.5;
        display: flex;
        border: none;
        font-size: 14px;
        font-weight: 500;
        .name {
          // flex: 4.8;
          font-size: 16px;
          color:#333333;
          width: 448.8px !important;

          .add-event {
            cursor: pointer;
            font-size: 14px;
            color: #333333;
            font-weight: 400;
            display: inline-block;
            padding: 0 5px;
            border-left: 1px solid #eeeeee;
            margin-left: 10px;
          }
        }
        div {
          // flex: 1;
          padding: 5px 0;
          
        }
        .cost {
          font-weight: 700;
          width: 110.8px!important;
          // padding-left: 20px;
        }
      }
      .action-row{
        .colunm.td{
          min-width: 50px;
          background-color: #ffffff;
        }
        .td{
          border-right: 1px solid #eeeeee;
          padding: 5px 0;
          text-align: center;
          background-color: #f5f5f5;
        }
      }
    }
    .shedule-of-event-panel-body {
      //   display: flex;
    }
    .f-1 {
      flex: 1;
    }
    .f-2 {
      flex: 2;
    }

    .ant-collapse-header {
      padding-left: 10px !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 !important;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0;
    }
    .anticon.anticon-right.ant-collapse-arrow {
      color: $theme-color;
    }
  }
  // hide the icon for input number type
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  input[type='number']{
      -moz-appearance: textfield;
  }
}
.f2-oncology{
  width: 150px
}
.cost-td-oncology {
  display: flex;
  align-items: center;
  width: 130px;

}

.visits-td-oncology {
  width: 128px
}

.modality-list-container{
  .modality-item{
    color: #333333;
  }
  .action-group{
    margin-top: 10px;
    .action{
      padding: 5px 0 5px 10px;
      border-top: 1px solid #eeeeee;
      cursor: pointer;
      color: #000000;
    }
    .little-icon {
      margin-left: -10px;
      margin-bottom: 3px;
      margin-right: 15px;
      vertical-align: bottom;
    }
    .whole{
      color: $theme-color;
    }
    .batch{
      color: #000000;
    }
    .is-disabled
    {
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

.modality-detail-container{
  display: flex;
  align-items: center;
}

.period-list-container{
  // background-color: #F2F2F2;
  .period-item{
    color: #333333;
  }
  .action{
    padding: 5px 0 5px 10px;
    border-top: 1px solid #eeeeee;
    cursor: pointer;
    color: #000000;
  }
  .little-icon {
    margin-left: -10px;
    margin-bottom: 3px;
    margin-right: 15px;
    vertical-align: bottom;
  }
  .no-border {
    border-top: 0px solid #eeeeee;
  }
  .action-group{
    margin-top: 10px;
    
    // .whole{
    //   color: $theme-color;
    // }
    // .batch{
    //   color: #000000;
    // }
    .is-disabled
    {
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

.periodNameInput {
  border: none !important;
  box-sizing: border-box;
  border: none;
  border-right: 1px solid #eeeeee;
  outline: none !important;
  &:last-child {
    border-right: 0px solid transparent;
  }
}

