@import "../../styles/variables.scss";
.create-new-trial-container {  
  background-color: #F2F2F2;
  .new-trial-wrapper {
    width: 80%;
    margin: 15px auto  0;
    overflow: hidden;
    .new-trial-content {
      background-color: #FFFFFF;
      padding: 20px 40px;
      .trial-title {
        color: #000000;
        // text-transform: uppercase;
        font: normal normal bold 14px/17px !important;
      }
      .trial-content {
        .navigation-title {
          color: #333333;
          font-weight: 600;
          margin-bottom: 30px;
          display: block;
        }
        .center-content {
          flex: 1;
          padding-top: 15px;
          .title {
            color: #000000;
            margin-bottom: 20px;
            display: block;
            .text {
              font-weight: 600;
              font-size: 24px;
            }
            .des {
              font-size: 16px;
            }
          }
        }
        .center-content.similar-table{
          // margin-left: -24px;
          margin-right: -24px;
          margin-bottom: -24px;
          padding-top: 0px;
          padding-left: 0px;
        }
        .nav-bar {
          padding: 20px 40px 10px;
          margin-left: -40px;
          margin-right: -30px;
          .ant-steps-label-vertical .ant-steps-item-content {
            margin-top: 2px;
          }
          .ant-steps-item .ant-steps-item-title {
            font-size: 14px;
          }
          .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
            color: #CF4500;
          }
          .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
            color: #999999;
          }
          .ant-steps-item-finish
            > .ant-steps-item-container
            > .ant-steps-item-tail::after {
            background-color: $theme-color;
          }
          .ant-steps-item-finish
            .ant-steps-item-icon
            > .ant-steps-icon
            .ant-steps-icon-dot {
            background: $theme-color;
          }
          .ant-steps-item-process
            .ant-steps-item-icon
            > .ant-steps-icon
            .ant-steps-icon-dot {
              background: #fff;
              border: 1px solid #CA4A04;
          }
          .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
            background: #D8D8D8;
          }
          .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
            color: #333333;
          }
          .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail{
            margin: 0 0 0 0px;
          }
          .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon,
          .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon{
            margin-left:unset;
          }
          .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail{
            width: calc(100% + 5px);
          }
          .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content{
            width: 100%;
            text-align:left;
          }
        }
      }
    }
    .new-trial-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      .create-preset-btn {
        // text-transform: uppercase;
        opacity: 1;
        display: inline-block;
        margin: 0 10px;
      }
      .preset-btn-enable {
        color: #CA4A04;
        cursor: pointer;
      }
      .preset-btn-disable {
        color: #CCAE9D;
        pointer-events: none;
      }
      .left-action {
        display: flex;
        .cancel-btn {
          background-color: #F2F2F2;
        }
        .go-prev-step {
          font-size: 14px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: $theme-color;
          // text-transform: uppercase;
          font-weight: 500;

          .anticon-left{
            color:#CA4A04;
          }
        }
      }
      button span{
        font-weight: 500;
      }
    }
  }
  
}
.SimialarHistoryModal {
  .ant-modal-content {
    width: 60% !important;
    float: right;
  }
}

.PresetModal {
  width: 50% !important;
   
  .preset-error {
    color: #F83203;
    margin-bottom: 10px;
  }
  .required {
    color: #F83203;
  }
  .warning {
    padding-right: 10px
  }
}




