@import "../../styles/variables.scss";
.intell-trial-layout {
  min-height: 100%;

  .ant-breadcrumb {
    // background-color: #dedede;
  }

  .ant-layout-header {
    background-color: #FFFFFF;
    display: flex;
    line-height: unset;
    padding: 0 30px;
    color: #ffffff;

    .header-left {
      display: flex;
      flex: 1;
      margin-left: -7px;
    }
    .header-right {
      display: flex;
      align-items: center;

      .ant-input-affix-wrapper{
        background-color: #F8F8F8;
        input.ant-input{
          background-color: #F8F8F8;
        }
        
      }

    }

    .system__info {
      display: flex;
      align-items: center;
      font-size: 18px;
      .system__info_title {
        // border-right: 1px solid #333333;
        // padding: 2px 20px 2px 2px;
        color:#333333;
        font-weight: 400;
        // cursor: pointer;
        margin-left: 4px;
        font-family: 'Helvetica Neue';
      }

      img {
        margin-right: 18px;
      }
    }
    .menu-box {
      .ant-menu-horizontal {
        // border-bottom: none;
      }
      .ant-menu-title-content {
        color: #333333;
        font-weight: 500;
        padding-bottom: 15px;
      }
      .ant-menu {
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        color: #333333;
        font-weight: 500;
        .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
          // color: #333333;
          // font-weight: 600;
          // padding-bottom: 15px;
          // border-bottom: 2px solid #ffffff;
        }
      }
      .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-item-selected::after {
        // border-bottom: 2px solid transparent;
      }
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
        .ant-menu-title-content {
          color: $theme-color!important;
        }
        // font-weight: 500;
      }
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
        // border-bottom: 2px solid transparent;
      }
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
        border-bottom: none!important;
      }

      .ant-menu-item-selected {
        .ant-menu-title-content {
          color: $theme-color!important;
        }
      }

      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
        border-bottom: 2px solid $theme-color;
      }
      .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
        bottom: -13px!important;
      }
    }

    .user__info {
      color: #212529;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
  }
}
.user__tag {
  width: 24px;
  height: 24px;
  background: #CA4A04;
  border-radius: 20px;
  text-align: center;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.info__pop {
  display: flex;

  .username {
    text-align: left;
    padding: 0 10px;

    div:first-child {
      font-weight: 500;
    }
  }

  .user__tag {
    margin-top: 8px;
  }
}
.ant-popover-message {
  border-bottom: 1px solid #ededed;
}
.ant-popover-message {
  margin-bottom: 20px;
}
.ant-popover-buttons {
  .ant-btn.ant-btn-sm:first-child {
    display: none;
  }
}
.ant-popover-buttons {
  text-align: left;
}
.ant-layout-footer {
  padding: 0;
}
.pwc-logo-placeholder {
  //  margin-top: 10px;
  //  display: inline-block;
  display: flex;
  align-items: center;
  
 }

 .pwc-logo-image {
     width:52px;
     height:25px;
	 }


