
.endpointDetailModal{
    display: flex;
    align-items: center; 
    justify-content: center;
    .endpoint-configure-title {
        text-align: left;
        font-size:  24px;
        color: #000000;
        padding: 5px 15px;
    }
    
    .endpoint-modal-filed {
        background: #F8F8F8;
        border: 1px solid #DDDDDD;
        margin-bottom: 5px;
        padding: 5px 15px;
        .informed-consent-label {
            text-align: left;
            font-size: 24px;  
            color: #000000;
            font-weight: bold;
        }
        
    }

   
    .timepoint-selector .input-list-wrapper {
        width: 100%;
        // padding-left: 100px;
        .row {
            margin: 8px 0px;
            .hist, .my {
                display: inline-block;
                width: 200px;
            }
        }
    }
     
    .endpoint-selector, .timepoint-selector, .endpoint-context{
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        padding-left: 50px;
        color: #333333;
        .title {
            font-weight: 700;
            .required {
                font-size: 10px;
                color: #d04a02
            }
        }
        .des {
            margin-left: 50px;
            // margin-bottom: 10px;
            .input-box {
                .limit {
                    color: #999999;
                    font-style: italic;
                }
            }
        }
        .input-error{
          text-align: left;
          padding: 5px 0px;
          color: #d04a02;
      }
    }
    
    .timepoint-selector {
        
        .header {
          display: flex;
          font-size: 14px;
          .title-text {
            font-size: 12px;
            font-weight: 700;
          }
          .endpoint {
            width: 100px;
          }
          .history {
            width: 200px;
            .top {

            }
            .bottom {
            //   font-style: italic;
            }
          }
          .modifier {
            width: 200px;
          }
        }
        .ant-table table { 
            border-spacing: 0 10px !important; 
            width: 500px;

            .ant-table-cell {
                padding: 4px 5px !important;
                .ant-form-item {
                  margin-bottom: 0!important;
                }
              }
        }
    }
  
    .endpoint-context .context-box {
      position: relative;
      z-index: 2; 
    }

    .button-wrapper {
      justify-content: flex-end;
      // padding-top: 10px;
      padding-bottom: 4px;
      position: sticky;
      bottom: 0;
      right: 0;
      position: relative;
      z-index: 2;
      .cancel-btn {
        text-align: center;
        font-size: 16px;
        color: #333333;
        background: #FFFFFF;
        margin-right: 10px;
        border: none;
    }
    .create-btn {
        display: flex;
        justify-content: center; 
        align-items: center;
        text-align: center;
        font-size:  16px;
        color: #FFFFFF;
        background: #CA4A04;
        margin-right: 20px;
    }
    }
    .ant-carousel .slick-dots li button {
        height: 15px;
        width: 15px;
        background-color: #FFFFFF;
        border-radius: 50%;
        border: 1px solid #707070;
    }
    .ant-carousel .slick-dots li.slick-active {
        width: 16px!important;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background-color: #999999;
        border: 1px solid #707070;
    }
    .wrapper {
        margin: 10px 0 0 0;
       
        .title {
            font-size: 14px;
            font-weight: 700;
        }
        .content-wrapper {
            height: 300px;
            width: 100%;
            display: flex;
            .left {
                width: 60%;
            }
            .right {
                width: 40%;
                .number {
                    font-size: 18px;
                    color: #000000;
                    margin-left: 20px;
                   }
                .count {
                   .text {
                    display: inline-block;
                    width: 180px;
                    color: #4E4E4E;
                    font-size: 12px;
                    font-weight: 600;
                   } 
                  }
                  .trending {
                    .text {
                      display: inline-block;
                      width: 180px;
                      color: #Eb8C00;
                      font-size: 12px;
                      font-weight: 600;
                     }  
                }
                .frequency {
                    .circle {
                        display: inline-block;
                        background-color: #295477;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .sec {
                        background-color: #4577C9;
                    }
                    .text {
                        color: #383A3C;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }

            }
            .whole {
                width: 100%;
            }
        }
    }
}


.endpointDetailModal.ant-modal-wrap{
    .ant-modal {
     width: 900px!important;
   }
   .ant-modal-content {
     border-radius: 10px;
     overflow-y: scroll;
   }
   .ant-modal-header {
     border-bottom: 1px solid #fff;
     border-radius: 10px;
   }
   .ant-modal-title{
     font-size: 18px;
     color: #000000;
   }
   .ant-modal-body{
     padding: 5px 0px 5px;
     max-height: 746px;
     .criterion-value {
       background-color: #F2F2F2;
       width: 100%;
       height: 95px;
       display: flex;
       padding: 20px;
       .item {
         flex: 1;
         .top {
           color:#656565;
           font-size: 14px;
         }
         .bottom {
           color: #000000;
           font-size: 16px;
           max-width: 260px;
         }
       }
     }
     
     .criteria-table {
       .ant-table-column-sorters {
         color: #333333;
         font-weight: 600;
         .ant-table-column-sorter {
           color: #333333;
         }
       }
     }
     
   }
 }
 .ant-input{
   font-size: 18px;
   color: #333;
 }
 .ant-modal-footer{
   .ant-btn-primary{
     font-size: 16px;
   }
 }

.ant-menu-vertical {
    border-right: none !important;
}

// .ant-dropdown-menu-title-content {
//     width: 100%;
// }

// .ant-dropdown-menu{  
//     .itemLine {
//         width: 100% !important; 
//     }
// }
