.pa-container {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 25px !important;
    }
    .pa-header-layout {
        background-color: #DEDEDE;
        padding: 2% 6% 1%;
        .sub-heading {
            font-size: 14px;
        }
    }
    .pa-body-layout {
       background-color: #F2F2F2;

       .top-description {
        padding: 1% 6% 1%;
       }
       .below-table {
        padding: 0% 6% 2%;
            .ant-table table {
                border-spacing: 0 0px !important;
            }
            .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
                 margin: 2px 10px 2px 10px!important; 
            }
            .sub-table .ant-table-content .ant-table-thead tr th.ant-table-cell{
                background-color: #E0E0E0!important;
                color: #000000!important;
            }
            .sub-table .ant-table-content .ant-table-tbody tr td.ant-table-cell{
                white-space: pre-line;
                vertical-align: top;
            }
        }
    }
}

