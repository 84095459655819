.summary-container {

    .chart-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0px;
        border-radius: 10px;
        background-color:#fff;
    }
    .left-chart {
        margin: 0px 70px 0 0;
    }
     
    .patient-burden-container {
        background-color: white;
        p {
            font-size: 16px;
            font-weight: 600;
        } 
    }
    
}