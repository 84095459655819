.activity-ie-container {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 25px !important;
    }
    .activity-soa-header_layout {
        background-color: #DEDEDE;
        padding: 2% 6% 1%;

        .sub-heading {
            font-size: 14px;
        }
    }
    .activity-soa-body_layout {
        .search-soa {
            border-radius: 20px;
            padding: 8px 15px;

            .ant-input {
                font-size: 14px;
            }
        }
        .search-soa-filters {
            border: 1px solid #d9d9d9; 
            border-radius: 20px;
            // float: right;
            padding: 5px;
            margin-left: 20px;
            width: 90%;
            text-align: center;
            background-color: #FFFFFF;
        } 
        .activities-container {
            background: #FFFFFF;

            .lists-container {
                max-height: calc(100% - 200px); 
                overflow-y: scroll;
            }

            .side-panel-heading {
                text-align: left;
                font-size: 22px; 
                color: #333333;
                padding: 10px 10px;
            }

            .sub-category {
                font-size: 16px; 
                color: #808080;
                padding-left: 5px;
            }
            .category {
                font-size: 16px; 
                color: #000000;
            }

            .soa-actiity-btn-container { 
                position: absolute;
                bottom: 0;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 25px;

                .add-soa {
                    display: flex;
                    justify-content: center; 
                    align-items: center;
                    background: #CA4A04;
                    border-radius: 2px;
                    color: #FFFFFF;
                    // text-transform: uppercase;
                    font-size: 18px;
                }
                .cancel-soa {
                    background: #FFFFFF;
                    border: 2px solid #CA4A04;
                    border-radius: 2px;
                    color: #CA4A04;
                    font-size: 16px;
                }
            }
        }
    }
}

.criteriaDetailModal {
    // left: 0% !important;
    display: flex;
    align-items: center; 
    justify-content: center;
    .ie-configure-title {
        text-align: left;
        font-size:  24px;
        color: #000000;
        padding: 5px;
    }
    
    .endpoint-modal-filed {
        background: #F8F8F8;
        border: 1px solid #DDDDDD;
        padding: 5px;
        margin-bottom: 5px;
        .informed-consent-label {
            text-align: left;
            font-size: 24px;  
            color: #000000;
            font-weight: bold;
        }
        .procedure-label {
            text-align: left;
            font-size: 16px; 
            color: #333333;
            font-weight: 600;
        }
    
        .label-header {
            font-size: 11px; 
            color: #383A3C;
        }
        .label-values {
            font-size:  22px; 
            color: #000000;
    
        }
    }
    
    .ie-criteria-selector {
        text-align: left;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        .header {
          display: flex;
          font-size: 14px;
          .criteria {
            width: 200px;
          }
          .history {
            width: 320px;
            .top {

            }
            .bottom {
            //   font-style: italic;
            }
          }
          .modifier {
            width: 320px;
          }

        }
    }

    .cancel-btn {
        text-align: center;
        font-size: 16px;
        color: #333333;
        background: #FFFFFF;
        margin-right: 10px;
        border: none;
    }
    .create-btn {
        text-align: center;
        font-size:  16px;
        color: #FFFFFF;
        background: #CA4A04;
    }
}

.category-filters {
    background: white;
    padding: 10% 2%;
    box-shadow: 0px 0px 17px #00000029;

    .apply-filters {
        float: right;
        background: #CA4A04 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        text-align: center;
        border-radius: 2px;
    }

    .cancel-filters {
        text-align: center; 
        color: #CA4A04;  
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #CA4A04;
        border-radius: 2px;
    }
}

.ant-menu-vertical {
    border-right: none !important;
}

.ant-table table { border-spacing: 0 10px !important; }

.ant-dropdown-menu-title-content {
    width: 95%;
}

.ant-dropdown-menu{  
    .itemLine {
        width: 95% !important; 
    }
}

.criteria-lib {
    .add-button {
        display: flex;
        justify-content: center; 
        align-items: center;
        background: #CA4A04;
        border-radius: 2px;
        color: #FFFFFF;
        // text-transform: uppercase;
        font-size: 16px;
    }
}