@import "../../styles/variables.scss";
.trail-list-container {
  padding: 30px 100px;
  .ant-collapse {
    border: 1px solid transparent;
    background-color: transparent;

    .ant-collapse-item {
      border-bottom: 1px solid transparent;
      margin-bottom: 20px;
      box-shadow: 0px 0px 4px #00000029;
    }
    .ant-collapse-header {
      background-color: #ffffff;
      padding-top: 0!important;
      padding-bottom: 0!important;
    }
    .ant-collapse-content {
      border: 1px solid #D6E1E4;
      background: #f3f6f7;
      box-shadow: 0px 2px 4px #a4b3b757;
    }
  }
  .list-columns {
    display: flex;
    padding: 5px 40px 5px 16px;
    div {
      flex: 1;
      color: #000000;
      font-size: 15px;
      font-weight: 500;
      &:last-child{
        max-width: 120px;
        text-align: right;
        padding-right: 15px;
      }
    }
  }
  .trial-panelHeader {
    display: flex;
    border-right: 1px solid #00000029;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff;
    .trial-panel-trialTitle {
      span span {
        &:nth-child(1) {
          pointer-events: none;
        }
      }
    }
    div {
      flex: 1;
      padding-right: 8px;
      padding-left: 2px;
      color: #333333;
      font-size: 14px;
      align-self: center;
      // text-transform: capitalize;
      &:last-child{
        max-width: 120px;
        text-align: right;
        padding-right: 15px;
        
      }
    }
    .header-word-wrap {
      max-width: 210px;
      display: block;
      overflow-wrap: break-word;
    }
    .status {
      display: flex;
      align-items: center;
    }
    .status-tag {
      border-radius: 3px;
      width: 6px;
      height: 6px;
      display: inline-block;
      margin-right: 2px;

      &.completed {
        background: #048b52;
      }
      &.in-progress {
        background: #e68700;
      }
    }
    .view-btn {
      padding: 5px;
      color: $theme-color;
      border: 1px solid $theme-color;
      font-size: 14px;
    }
    .update-time {
      color: #999999;
      font-size: 12px;
      display: block;
      margin-top: -4px;
    }
  }
  .trial-panelBody {
    display: flex;
    .trial-body-trialTitle {
      span span {
        &:nth-child(1) {
          pointer-events: none;
        }
      }
    }
    div {
      flex: 1;
      padding-right: 8px;
      padding-left: 2px;
      &:last-child{
        max-width: 120px;
        text-align: right;
        padding-right: 15px;
      }
    }
    .key {
      color: #999999;
      font-size: 14px;
    }
    .value {
      color: #333333;
      font-size: 12px;
    }
    .view-endpoint {
      color: #e68700;
    }
  }

  .module_status_item {
    display: flex;
    .m-icon {
      margin-left: 10px;
      display: inline-block;
      width: 26px;
      height: 26px;
      border: 1px solid #e3e1e5;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-style: normal;
        display: flex;
        background: #644c8c;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        color: #ffffff;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 16px;
}

.module_status_tooltip {
  min-width: 250px;
}

.module_status_wrapper {
  color: #000000;
  font-size: 12px;
  .title {
    font-weight: 600;
    margin-bottom: 10px;
  }
  .status_row {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    .name {
      flex: 1;
    }
    .status {
      color: #5d5d5d;
      font-size: 11px;
      padding: 0 5px;
      border-radius: 8px;
      &.in_progress {
        background-color: #e68700;
      }
      &.not_started {
        background-color: #e2e2e2;
      }
    }
  }
}
.trails-list
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 8px 40px 8px 10px;
}
.trails-list {
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    right: 16px;
    left: auto;
    top: 45%;
    padding: unset;
  }
}
.no-data {
  font-size: 20px;
  text-align: center;
  padding: 20px 0;
}
