@import "../../styles/variables.scss";
.Filter {
    width: 382px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #00000066;
    border-radius: 14px;
    opacity: 1;
    float: right;
    padding: 2%;
    margin-right:10px;
    position: absolute;
    z-index: 1;

    .filter-selector { 
        .selector-item {
          margin-right: 20px;
          label {
            color: #000000;
            font-size: 11px;
            font-weight: bold;
          }
          .ant-select{
            color: #354052;
          }
        }
      }
      .filter_btn-group {
        display: flex;
        justify-content: right;

        Button {
            font-size: 16px;
            padding: 20px 20px;
            height: 30px;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: 10px;
            border: none;
        }

        .apply-filter {
            background-color: $theme-color;
            color: #ffffff;
        }
    
        .cancel-filter {
            margin-right: 10px;
            background-color: white;
            color: black;
        }
      }
     
}