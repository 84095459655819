.modality-detail-container{
    display: flex;
   align-items: center;
  }
.endpoint-ellipsis {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: all;
  }