.soa-at-glance {
    .chart-header {
        .icon-holder {
            display: inline-block;
            float: right;
        }
        .title {
            width: 100%;
        }
    }
    
    .chart-container {
        display: flex;
        justify-content: space-around;
        padding: 10px 10px 10px 2px;
        .left-part {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        flex: 1;
        padding: 0 0 0 10px;
        .item-wrapper-top-para {
            padding-top: 5px;
            padding-left: 4px;
            // height: 44px;
            margin: auto;
            font-size: 14px;
            font-weight: 600;
        }
        .item-wrapper-sub-header {
            margin: auto;
            font-size: 10px;
            font-weight: 600;
        }
        .item-wrapper {
            // width: 106px;
            width: 85%;
            height: 45px;
            margin: auto;
            flex: 1;
            margin-bottom: 13px;
            padding: 8px;
            font: normal normal bold 24px/17px;
            .text {
                color: #797979;
                font-size: 14px;
                font-weight: 500;
            }
            .number {
                color: #6F2849;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 5px;
            }
        }
        .item-wrapper-top {
            margin-top: 15px;
            // padding-top: 15px;
            background-color: #F2F2F2;
            .number {
                color: #FF6C40;
            }
        }
        .item-wrapper-bottom { 
            background-color: #F2F2F2;
            .number {
                color: #DC7075;
            }
        }

        }
        .middle {
            width: 1px;
            height: 300px;
            margin: 30px 0 40px 10px;
            border-left: 1px dashed #C1C1C1;
        }
        .right-part {
        flex: 4;
        padding: 10px 20px;
        display: flex; 
        flex-direction: column;
        .title {
            font-size: 14px; 
            line-height: 14px;
            margin-left: 20px;
            margin: 20px 0 30px 0;
            
        }
        .chart-content {
            .blank {
                font: normal normal normal 14px; 
                line-height: 30px;
            }
        }
        }
    }
}