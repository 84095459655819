@import "./variables";

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: $white;
  font-family:  "Helvetica Neue", "Arial", sans-serif ;
}

.main__content {
  min-height: 100vh;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
  border-bottom: 2px solid $theme-color;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  font-weight: 300;
}

.ant-menu-horizontal .ant-menu-item:hover,
.ant-menu-horizontal .ant-menu-submenu:hover,
.ant-menu-submenu-title:hover {
  font-weight: 500;
  //  color:$white!important;
  //  border-bottom: none !important;
}

.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected,
.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected {
  // color:$white;
  border-bottom: none;
  font-weight: 500;
}
.ant-menu-horizontal > .ant-menu-item a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  // color: $white;
  text-decoration: none;
}
.ant-menu-item a:hover,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $theme-color;
  text-decoration: none;
}

.ant-layout-content {
  background-color: $background-gray;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #212529;
}
.interactive__btn {
  cursor: pointer;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  background-color: $theme-color;
  border-color: $theme-color;
}

.ant-layout-header .ant-menu .ant-menu-item {
  // font-size: 10px !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin-left: 10px;
  margin-right: 10px;
}
.ant-layout-content {
  background-color: #f5f5f5;
}

.ant-layout.intell-trial-layout {
  .ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 111;
    height: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  .ant-layout-content {
    padding-top: 50px;
  }
}
// .ant-radio-inner::after,
.ant-radio-checked .ant-radio-inner,
.ant-radio-checked .ant-radio-inner:focus {
  border-color: $theme-color !important;
}
.ant-radio.ant-radio-checked .ant-radio-inner::after {
  background-color: $theme-color;
}
.ant-btn:hover,
.ant-btn:focus {
  color: $theme-color;
  border-color: $theme-color;
  background-color: #ffffff;
}

.anticon.anticon-edit,
.anticon-double-left,
.anticon.anticon-double-left,
.ant-pagination-jump-next,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: $theme-color;
}
.ant-table-wrapper {
  background-color: #ffffff;
}
ul.ant-table-pagination {
  padding-right: 10px;
}
.ant-pagination-item-active,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $theme-color;
  color: $theme-color;
  a {
    color: $theme-color;
  }
}
.ant-pagination-item-active a,
.ant-pagination-item-active a:focus {
  color: $theme-color;
}
.ant-pagination-options .ant-select-open:hover,
.ant-select.ant-select-sm.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow:hover {
  border-color: $theme-color !important;
}

.ant-drawer.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  min-width: 1000px !important;
}

.ant-checkbox-checked::after {
  border: 1px solid $theme-color;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $theme-color;
  border-color: $theme-color;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #ffffff;
}

.count.count-icon {
  padding: 0 4px;
  color: #ffffff;
  border-radius: 2px;
  margin-left: 5px;
  &.active-count {
    background-color: $theme-color;
  }
  &.inactive-count {
    background-color: gray;
  }
}

.ant-spin-dot-item {
  background-color: $theme-color;
}

.ant-table-content .ant-checkbox-indeterminate .ant-checkbox-inner::after{
  background-color:$theme-color;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color:$theme-color;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color:$theme-color;
}
