@import "../../styles/variables.scss";
.soa-view {
    
    .ant-row .ant-col .side-toolbar{
        position: relative;
        z-index: 109;
        color:white;
        background-color: #F8F8F8;
        min-height: 100%;
        width: 50px;
        align-items: center;
        cursor: pointer;
        border: 1px solid #DDD;
        
        .panel-label{
            display:none;
        }
        .icon{
            display:inline-block;
        }

        .anticon-arrow-right{
            font-weight: 900;
            font-size: 16px;
            transform: translateY(-1px);
        }

        .action-wrapper {
            position: absolute;
            right: -11px;
            top: 9px;
            width: 12px;
            height: 20px;
            border: 1px solid #DDD;
            border-radius:0 20px 20px 0;
            background-color: #F8F8F8;;
            border-left: 1px solid #F8F8F8;;
          }
        .action-title {
            position: absolute;
            right: -9px;
            top: 7px;
            font-size: 14px;
            padding-left: 8px;
            height: 50px;
            font-weight: 600;
            cursor: pointer;
            text-align: center;
            color:#CA4A04;
            span {
                font-size: 13px;
            }
          }

    }

    .side-toolbar.hidden{
        display : none;
    }

    .event-left-container .item-header .ant-row{
        .action-wrapper {
            position: absolute;
            right: -15px;
            top: 4px;
            width: 12px;
            height: 20px;
            border: 1px solid #DDDDDD;
            border-radius: 20px 0 0 20px;
            background-color: #fff;
            border-right: 1px solid #fff;
          }
        .action-title {
            position: absolute;
            right: -17px;
            top: -3px;
            font-size: 14px;
            padding-left: 8px;
            height: 50px;
            font-weight: 600;
            cursor: pointer;
            text-align: center;
            color:#CA4A04;
            span {
                font-size: 13px;
            }
          }
    }
}

// .ant-modal-wrap {
//     margin-top: 170px !important;
// }
.event-left-container{
    background-color: #f3f3f3;
    min-height: calc(100vh - 141px);

    .event-section{
        background-color: #fff;
        font-size: 14px;
        margin-top: 10px;

        .section-header{
            font-weight: 500;
            font-size: 16px;
            color: #333;

            .count-span{
                margin-left: 5px;
                padding: 0px 3px;
                background-color: #333;
                color: #fff;
                font-size: 12px;
            }

            .collapse-icon{
                .anticon-plus, .anticon-minus{
                    color: #999;
                }
            }
        }

        .eventLib.ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header{
            padding: 6px 16px;
        }

        .eventLib.ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
            padding: 0px;
        }

        .ant-table-tbody > tr > td{
            padding: 6px 10px 6px 16px;
            font-size: 16px;

            .add, .remove{
                cursor: pointer;
                text-align: right;
            }
            .add{
                color: #CA4A04;
            }
            .remove{
                color:#A5A5A5;
            }
            .event-text{
                color:#333333;
            }
        }
    }
}

.event-right-container{
    height: 100%;
    width: 75%;
    .ant-dropdown-button{
        border: 1px solid #CA4A04;
    }
    .anticon.anticon-down{
        color: #CA4A04;
    }
    .anticon.anticon-down.ant-input-number-handler-down-inner, .anticon.anticon-up.ant-input-number-handler-up-inner{
        color:#666;
    }
    .ant-btn-group.ant-dropdown-button{
        .ant-btn.ant-btn-default{
            color:#CA4A04;
            border: unset;
        }
        .ant-btn.ant-btn-default.ant-btn-icon-only.ant-dropdown-trigger{
            border-left: 1px solid #CA4A04;
        }
    }

    .ant-input-number .ant-input-number-handler-wrap {
        opacity: 1;
        border-left: unset;
    }

    .ant-input-number .ant-input-number-handler-down{
        border-top: unset;
    }

    .ant-input-number.ant-input-number-sm {
        width: 60px;
    }

    .hidde{
        // display: none;
    }

    .right{
        float: right;
        justify-content:right;
        text-align: right;
        align-items:right;
    }
    .left{
        justify-content:left;
        text-align: left;
        align-items:left;
    }
    .center{
        justify-content:center;
        text-align: center;
        align-items:center;
    }
    .tip1-desc.center{
        display:inline-block;
    }

    .none-click{
        font-size: 16px;
        color: #333;
        pointer-events: none;
        input{
            color: #333;
        }
    }

    .ant-input-number-disabled {
        color:  #333;
        background-color: #fff;
    }
    
    .anticon-edit.edit-icon{
        display:inline-block;
        color: #999;
    }
    .anticon-edit.edit-icon.active{
        color: #CA4A04;
    }

    .event-chart{
        margin-top: 20px;
    }

    .event-panelHeader{
        .bar-desc {
            font-size: 16px;
            font-weight: bold;
            display:inline-block;
            min-width:70px;
            border-right: 2px solid #ddd;
        }

        .item-desc {
            margin-left:20px;
            display:inline-block;

            span{
                font-size:12px;
            }

            .bar-item{
                display:inline-block;
                content: "";
                width: 7px;
                height: 7px;
                border-radius: 5px;
                margin-right:5px;
            }

            .item1{
                background-color: #d04a02;
            }

            .item2{
                background-color: #ed7738;
            }

            .item3{
                background-color: #ed9d72;
            }

            .item4{
                background-color: #f5b795;
            }

            .item5{
                background-color: #f3d5c5;
            }
        }

        .header-section{
            font-weight: 500;
        }
    }
}

.configure-title{
    justify-content: center;
    span{
        font-size: 18px;
        color:#333;
    }
}
.configure-desc{
    justify-content: center;
    span{
        font-size: 15px;
        color:#999;
    }
}
.modal-filed{
    .label{
        text-align: right;
        padding: 5px 10px;
        transform: translateY(5px);
        span{
            color: #333;
        }
    }

    .input-number{
        padding: 5px 10px;
        input{
            color: #333;
            font-size: 14px;
        }
    }

    .anticon.anticon-down.ant-input-number-handler-down-inner{
        color:#666;
    }

    .ant-input-number .ant-input-number-handler-wrap {
        opacity: 1;
        border-left: unset;
    }

    .ant-input-number .ant-input-number-handler-down{
        border-top: unset;
    }

    .ant-input-number.ant-input-number {
        width: 70px;
    }

    .input-number-error{
        text-align: center;
        padding: 5px 10px;
        transform: translateY(5px);
        padding: 5px 5px;
        color: #d04a02;
    }
}

.step-btn.create-btn span{
    color: #ffffff;
    font-size: 14px;
}