.new-scenario-container {
  .ant-tabs > .ant-tabs-nav {
    display: none !important;
  }

   .scenario-header_layout {
        background-color: #DEDEDE;
        padding: 2% 6% 0;

        .view_similar_trials_btn {
          background-color: #DEDEDE;
          color:#000000;
          border: none;
          font-weight: 400;
        }

        .home {
            display: inline-block; 
            max-width: calc(100%);
            font-size: 12px;
            font-weight: 600; 
            span > span {
              &:nth-child(1) {
                pointer-events: none;
              }
            }
        }

        .flex-container {
            display: flex;
            flex-wrap: wrap;
            font-size: 30px; 

            .flex-item-left { 
              padding: 10px;
              flex: 65%;
              max-width: calc(65%);
              padding-left: 25px;
            }
            
            .flex-item-right { 
              padding: 10px;
              flex: 35%;
              max-width: calc(35%);

              .save_and_finish_btn {
                background-color: #F2F2F2;
                border-radius: 2px;
                color: #ca4a04;
                border: none;
              }
  
              .btn-container-header {
                float: right;
              }
            }
          }

        .tabs-container {
        display: flex;
        flex-direction: row;
        .active-tab-name {
            font-size: 16px;
            width: 176px;
            text-align: center;
            // height: 63px;
            line-height: 50px;
            cursor: pointer;
        }
        .active {
            color: #CA4A04;
            background: #F2F2F2 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0 0;
        }
    }
   }

   span.anticon.anticon-caret-right.ant-collapse-arrow {
    position: absolute;
    top: 5.0005px;
    left: 16px;
    padding: 12px 16px;
    padding-left: 0;
    font-size: 12px;
   }
}

.soa-simialar-table-wrapper {
  margin-top: -60px;
}

.my-legend-wrapper-soa{
  padding: 19% 0;
  margin-left: -20%;
  .custom-legend{
    .my_legend{
      display: inline-block;
      width: 26px;
      height: 6px;
      border-radius: 1px;
      margin-right: 4px;
    }
    .my_legend_text{
      color: #666666;
      font-size: 10px;
      font-style:normal;
    }
  }
}

.ant-table-thead > tr > th {
  background: #666666 !important;
  color: white !important;
}
.ant-btn.ant-btn-primary[disabled], 
    .ant-btn.ant-btn-primary[disabled]:hover, 
    .ant-btn.ant-btn-primary[disabled]:focus, 
    .ant-btn.ant-btn-primary[disabled]:active {
      color: rgba(0, 0, 0, 0.25) !important;
      background: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
      text-shadow: none !important;
      box-shadow: none !important;
      cursor: default!important;
    }
