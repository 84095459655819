@import "../../../styles/variables.scss";
.historical-trial-list {

  .ant-spin.ant-spin-spinning{
    span{
      font-size: 20px;
    }
  }
  .ant-checkbox-inner {
    width: 17px;
    height: 17px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    // transform: rotate(36deg) scale(1, 0.5) translate(-90%, -90%) !important;
    opacity: 0 !important;
  }

  .ant-switch-checked {
    background-color: #ca4a04 !important;
}
  .ant-checkbox-indeterminate .ant-checkbox-inner:after { 
    border-radius: 25px;
    border-radius: 10px solid #ca4a04;
    background-color: #1890ff;
    border: 0;
    content: " "; 
    left: 50%;
    opacity: 1;
    top: 50%;
    transform: translate(-50%,-50%) scale(0.55);
    height: 16px;
    width: 16px;
}
  .step-desc {
    color: #ddd;
  }
  .ant-drawer-wrapper-body {
    span {
    color: white !important;
  }}
  .indication-wrapper {
    display: flex;
  }
  .filter-item {
    margin-bottom: 25px;
    padding-left: 20px;
    padding-right: 30px;
    &.find-trial-btn-wrapper{
      text-align: center;
      button.ant-btn{
        border-color: $theme-color;
        span{
          color: $theme-color;
        }
      }
    }
    .ant-select.ant-select-show-arrow .ant-select-arrow .anticon{
      color: $theme-color;
    }

    label {
      margin-bottom: 5px;
      font-size: 10px;
      font-weight: 600;
      color: #000000;
      padding-right: 5px;
    }
    &:last-child label{
      margin-bottom: 0;
    }
    .ant-slider.ant-slider-with-marks {
      .ant-slider-mark-text {
        font-size: 8px;
      }
      .ant-slider-handle {
        border-color: $theme-color;
      }
      .ant-slider-track {
        background-color: $theme-color;
      }
      .ant-slider-dot-active {
        border-color: $theme-color;
      }
    }
    .ant-select-selector .ant-select-selection-search input{
      width: 95%;
      overflow: 'hidden';
      text-overflow: 'ellipsis';
    }
  }

  .filter-item.filter-label{
    padding: 20px;
    border-bottom: 1px solid #D8D8D8;
    span{
      color: #333333;
      font-size: 16px;
    }
  }

  .trial-historical-trial-error{
      font-size:13px;
      color: #d04a02;
  }

  .side-bar {
      font-size: 10px;
      color: #787878;
      margin-top: 20px;

    .filter-icon {
      border: 1px solid #ddd;
      width: 30px;
      height: 30px;
      padding-top: 5px;
      margin-bottom: 30px;
    }

    .side-bar-close {
      padding-top: 10px;
      .filter-button {
        width: 36px;
        height: 36px;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        cursor: pointer;
        box-shadow: 0px 1px 3px #31393F33;
      }
    }
    .side-bar-filter {
      border-right: 1px solid #ddd;

      .ant-tabs-ink-bar {
        background: #CA4A04 !important;
      }

      .ant-tabs-tab:hover {  
        color: #CA4A04; /* Inherit the text color */  
      } 
    
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: black !important;
      }
      .preset-name {
        color: #CA4A03;
        text-transform: capitalize;
        opacity: 1;
        text-decoration: underline;
        font-size: 14px;
      }
      .preset-count {
        font-size: 12px; 
        color: #252525;
      }
      .presets-created-by {
        font-size: 11px;
        font-style: italic;
        color: #807D7D;
      }
      .preset-count-conatiner {
        font-size: 11px;
        color: #6C6C6C;
      }
      .presets-created-name {
        color: #CA4A03;
        text-decoration: underline;
      }
    }
  }

  .historical-desc {
    padding: 30px;
    .num-desc {
      font-size: 12px;
    }

    .title{
      margin-bottom: 10px !important;
      font-size: 18px;
      font-weight: 600;
    }
    .title-desc{
      color: #999999;
      font-size: 15px;
      margin-bottom: 10px;
    }
    .title-preset-display {
      margin-bottom: 10px;
      color:#CA4A03;
      text-decoration: underline;
      font-size: 20px;
    }

    ul.ant-pagination{
      border-left: 1px solid #31393F33;
      border-right: 1px solid #31393F33;
      border-bottom: 1px solid #31393F33;
      margin-top: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .ant-table-container .ant-table-content{
      border: 1px solid #31393F33;

      .ant-table-cell {
        padding: 16px 9px !important;
      }

      .ant-table-thead > tr > th{
        background: #666666;
        color: #fff;
      }

      thead > tr:first-child > th {
        background: #666666;
        color: #fff;
      }

      thead > tr.search-row > th > div {
        margin: 3px 2px;
        span .ant-input{
          font-size: 14px;
        }
      }

      .ant-table-tbody > tr > td{
        color: #333333;

        .nctid{
          color: #CA4A04;
        }
      }
    }
  }
  .update-warning{
    background: #fbebd3;
    font-size: 14px;
    color: #a65729;
    width: 100%;
    padding: 4px 8px;
  }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
     .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 25px;
        padding-left: 5px;
    }

}

.chart-wrapper {
  border-right: 1px solid #31393F33;
  border-left: 1px solid #31393F33;
  padding-bottom: 10px;
  padding-top: 5px;
  display: flex;
  width: 100%;
  .chart {
    flex: 2;
    width: 344px;
    div {
      width: 344px;
    }
  }
}

.count-wrapper {
  display: flex;
  flex-direction: row;
  .summary-count:nth-child(1) {
    width: 50%;
  }
  .summary-count:nth-child(2) {
    width: 50%;
  }
  .summary-count {
    border-top: 1px solid #f7f7f7;
    border-bottom: 1px solid #31393F33;
    border-right: 1px solid #31393F33;
    border-left: 1px solid #31393F33;
    font-size: 24px;
    color: #000000;
    padding-left: 20px;
  
    .num-desc{
      color: #999999;
      font-size: 12px;
    }
  }
}

.chart-wrapper-row{
    height: 200px !important;
    .my-legend-wrapper{
      padding: 19% 0;
      margin-left: -20%;
      .custom-legend{
        .my_legend{
          display: inline-block;
          width: 26px;
          height: 6px;
          border-radius: 1px;
          margin-right: 4px;
        }
        .my_legend_text{
          color: #666666;
          font-size: 10px;
          font-style:normal;
        }
      }
}
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #D2D7E2;
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: #D2D7E2;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}