
@import "../../styles/variables.scss"; 

.ant-select-selector {
  border-color: #d9d9d9 !important;
  padding: 0 !important;
}

.complete-scenarios-module {

    .compare-scenario-box {
      box-shadow: 0px 1px 3px #31393F33;
      background: #FFFFFF
    }
    
    .record-container {
      padding: 30px;
      border-bottom: 1px solid #31393F33;
    }

    .metric-container {
      padding: 5px 30px;
      background: #F8F8F8;
      border-radius: 3px;
      font-size: 16px;
      font-weight: bold;
    }

    .compare-scenario-row {
        display: flex;
        padding: 0 30px;
        flex-wrap: wrap;

        .compare-scenario-col {
          flex: 1;
          padding: 2%; 
          //grid-template-rows: 1fr 1fr;  
          
          .title-container {
            border-right: 1px solid rgba(49, 57, 63, 0.2); 
            .scenario_name {
              span span span span {
                &:nth-child(1) {
                  pointer-events: none;
                }
              }
            }
            .trial_des {
              span span {
                &:nth-child(1) {
                  pointer-events: none;
                }
              }
            }
          }

          .metrics {
            padding: 1px;
            font-size: 16px;
            height: 50px;
          }

          .metrics1 {
            min-width: 55px;
            display: inline-block;
          }

          .metrics-indicator {
            padding: 8px 0;
            text-align: left;
            font-size:  15px; 
            color: #383A3C; 
            height: 50px;
          }

          .metrics-value {
            font-size: 16px;
            height: 50px;
          }

          .col-contents {
            padding: 2%;
          }

          .finalise {
            color: $theme-color  !important;
            font-size: 16px;
            position: absolute;
            bottom: 0;
          }

          .ant-select { 
            .ant-select-selector {
              border: none !important;
              padding: 0 !important;

              .ant-select-selection-item {
                color: #0a0a0a;
                font-size: 24px;
                font-weight: 600; 
              } 
            }
            .ant-select-arrow {
              color: black;
              font-size: 15px;
            }
          } 

          .col-child {
              padding: 8px 0;
          }
           div { 
            .status {
              font-size: 16px;
              font-weight: bold;
                display: inline;
                &.HIGH { 
                  color: #C33232;
                  padding: 1px 5px;
                }
                &.LOW { 
                  color: #00A947;
                  padding: 1px 5px;
                }
                &.MEDIUM {
                  color: #0084A9;
                  padding: 1px 5px;
                }
              }
           }     
        }
    }

    .home {
      display: block;
      font-size: 12px;
      font-weight: 600;
    }

    .compare {
      display: block;
      font-size: 24px;
    }

    .ant-breadcrumb {
      padding: 20px 0 30px 10px; 
      color: #999999;
      a {
        text-decoration: none;
      }
      .homepage {
        cursor: pointer;
        color: $theme-color;
        .anticon.anticon-home {
          margin-right: 8px;
          transform: translateY(-4px);
        }
      }
      .ant-breadcrumb-link{
        color: #999999;
      }
    }
}