$header-height: 123px;
$theme-color: #ca4a04;
$dp-header-height: 80px;
$white: #ffffff;
$background-gray: #f5f5f5;
$complete: #18D300;

// entity color directory
$MEDICAL_CONDITION: rgb(167, 159, 246);
$MEDICATION: rgba(247, 203, 129, 1);
$PROTECTED_HEALTH_INFORMATION: rgba(150, 234, 235, 1);
$TEST_TREATMENT_PROCEDURE: rgba(198, 224, 217, 1);
$TIME_EXPRESSION: rgba(253, 235, 143, 1);
$ANATOMY: rgba(123, 150, 200, 1);
$TIME_EXPRESSION: rgba(10, 243, 224, 0.308);
$MEDICAL_CONDITION: rgba(7, 104, 160, 0.13);
$DISEASE: #ff9a44;
$SIMPLE_CHEMICAL: rgba(150, 234, 235, 1);
$CELL: rgba(198, 224, 217, 1);
$CANCER: rgba(253, 235, 143, 1);
$CELLULAR_COMPONENT: rgba(123, 150, 200, 1);
$GENE_OR_GENE_PRODUCT: rgba(247, 203, 129, 1);
$IMMATERIAL_ANATOMICAL_ENTITY: rgba(65, 168, 125, 0.2);
$ORGANISM_SUBDIVISION: rgba(160, 185, 14, 1);
$ORGANISM_SUBSTANCE: rgba(173, 138, 180, 1);
$PATHOLOGICAL_FORMATION: rgb(60, 134, 231);
$SIMPLE_CHEMICAL: rgb(79, 199, 10);
$GGP: rgb(230, 172, 182);
$CHEBI: rgb(215, 230, 172);
$TAXON: rgb(172, 230, 177);
$GO: rgb(173, 172, 230);
$CELL_LINE: rgb(209, 172, 230);
$DNA: rgb(78, 112, 46);
$PROTEIN: rgb(96, 85, 155);
$AE: rgb(215, 236, 91);
$CHEMICAL: rgb(87, 135, 163);
$ORGANISM: rgb(78, 138, 30);
