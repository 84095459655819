.trial-detail-container {
  padding: 20px 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px #00000029;

  .top {
    display: flex;
    margin-bottom: 20px;
    .title {
      flex: 1;
      .nct-id {
        color: #ca4a04;
        font-size: 12px;
      }
      .trail-alias {
        font-size: 28px;
        color: #333333;
         span > span > span {
          &:nth-child(1) {
            pointer-events: none;
          }
        }
      }
      .update-time {
        color: #333333;
        font-size: 13px;
      }
    }
    .setting-action{
      color: #999999;
    }
    .setting-action.hidden{
      display: none;
    }
    .status {
      color: #000000;
      font-size: 12px;
      padding-left: 10px;
      // text-transform: uppercase;

      .my_icon.in-progress-icon, .my_icon.complete-icon{
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 5px;
        border-radius: 3px;

        &.in-progress-icon {
          background-color: #FFC107;
        }
        &.complete-icon {
          background-color: #18D300;
        }
      }
    }
    .team-member-wrapper {
      .user_tag {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #CA4A04;
        border-radius: 28px;
        border: 1px solid #fff;
        text-align: center;
        line-height: 26px;
        color: #ffffff;
        cursor: auto; 
        font-size: 14px;
        position: relative;
      }
      .user_tag:nth-child(1) {
        margin-left: 20px;
      }
      
      .user_tag:nth-child(n+2) {
        margin-left: -6px;
      }
      .user_tag.hidden{
        display: none;
      }
      .button {
        cursor: pointer;
        background: #fff;
        color: #999999;
        border: 1px solid #5A5353;
        z-index: 1;
        margin-left: -6px;
        .icon {
          color:#999999;
          margin-top: -5px;
          vertical-align: middle;
          height:24px;
          width: 24px;
          padding:1px
        }
      }
    }
  }

  .info {
    .info-row {
      display: flex;
      .trial-item {
        flex: 1;
        margin-bottom: 10px;
        margin-right: 50px;
        .required {
          font-size: 10px;
          color: #d04a02
        }
        label {
          color: #999999;
          font-size: 14px;
          display: block;
        }
        .readonly-value {
          display: block;
          font-size: 15px;
          color: #333;
          padding-bottom: 10px;
        }
        .trial-title-text {
          span span span{
            &:nth-child(1) {
              pointer-events: none;
            }
          }
        }
        .view-endpoint {
          // cursor: pointer;
        }
        .trial-details-error{
            color: #d04a02
        }
      }
      .trial-item-hide {
        flex: 0.8;
      }
    }
  }

  .edit-save-btn {
    text-align: right;
  }

  .indication-warning{
    font-size: 12px;
    color:#333333;
    font-weight: 600;
    width: 200px;
    .asterisk{
      color:red;
    }
  }
}

.ant-tooltip-content .action-list {
  color: #333333;
  div {
    cursor: pointer;
  }
}
.edit-detail-tooltip .ant-tooltip-content .ant-tooltip-inner{
  padding: 15px;
}

.ant-tooltip-content .action-list_disabled {
  color: rgba(0, 0, 0, 0.25);
  // background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  div {
    pointer-events: none;
  }
}
