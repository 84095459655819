
.endpoints-library-container {
    background-color: #F2F2F2;
    padding: 0% 0% 0% 6%;
    .endpoints-actions-container {
        display: flex;
        flex-wrap: wrap;
        font-size: 30px; 
        .left-content-container {
            .scenarios-heading-left { 
                padding: 10px;
                flex: 50%;
                max-width: calc(50%);
                // padding-left: 25px;
          
                span {
                  color: #333333;
                  font-size: 26px;
                }
          
                p {
                  font-size:  16px;
                }
              }
            .summary-container {
                display: flex;
                justify-content: space-around;
                border-bottom: 1px solid #00000029;
                .chart-wrapper {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 16px;
                    // margin: 0px 0px 30px 40px;
                    border-radius: 10px;
                    background-color:#fff;
                    border: 1px solid rgba(49, 57, 63, 0.2);
                    .chart-header {
                        color: #333333;
                        justify-content: space-between;
                        .title {
                            font-size: 16px;
                            font-weight: 700;
                            border-bottom: 1px solid #00000029;
                            padding: 10px 20px;
                        }
                    }
                    .chart-container.cost-chart {
                        padding: 10px 20px;
                        height: 300px;
                        font-size: 16px;
                        .description {
                            font-size: 16px;
                            height: 28px;
                            color: #E53500;
                            margin: 0;
                        }
                        .legend {
                            .item-desc {
                                width: 70%;
                                line-height: 15px;
                                display: flex;
                                justify-content: space-between;
                                .item {
                                    width: 120px;
                                }
                                span{
                                    font-size:12px;
                                    color: #333;
                                }
            
                                .bar-item{
                                    display:inline-block;
                                    content: "";
                                    width: 7px;
                                    height: 7px;
                                    border-radius: 5px;
                                    margin-right: 5px;
                                    margin-left: 5px;
                                }
            
                                .item1{
                                    background-color: #6F2849;
                                }
            
                                .item2{
                                    background-color: #9C3F5D;
                                }
            
                                .item3{
                                    background-color: #C8586C;
                                }
            
                                .item4{
                                    background-color: #DC7075;
                                }
                            }
                        }
                        .center-box {
                            display: flex; 
                            height: 230px;
                            .copy-width {
                                width: 65%;
                                margin-top: 30px;
                            }
                            .pie-width {
                                width: 35%;
                            }
                            .label {
                                max-width: 300px; 
                            }
                            .detail {
                                color:#CA4A04;
                                font-weight: 500;
                                font-size: 14px;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                    .chart-container.glance-chart {
                        display: flex;
                        justify-content: space-around;
                        padding: 10px 10px 10px 2px;
                        .left-part {
                        display: flex;
                        justify-content: space-around;
                        flex-direction: column;
                        flex: 1;
                        padding: 0 0 0 10px;
                        .item-wrapper-top-para {
                            padding-top: 5px;
                            padding-left: 2px;
                            height: 78px;
                            margin: auto;
                            font-size: 14px;
                            font-weight: 600;
                        }
                        .item-wrapper {
                            // width: 106px;
                            width: 90%;
                            height: 45px;
                            margin: auto;
                            flex: 1;
                            margin-bottom: 20px;
                            padding: 8px;
                            font: normal normal bold 24px/17px;
                            .text {
                                color: #797979;
                                font-size: 14px;
                                font-weight: 500;
                            }
                            .number {
                                color: #6F2849;
                                font-size: 24px;
                                font-weight: 700;
                                margin-bottom: 5px;
                            }
                        }
                        .item-wrapper-top {
                            margin-top: 10px;
                            padding-top: 15px;
                            background-color: #F2F2F2;
                            ;
                            .number {
                                color: #FF6C40;
                            }
                        }
                        .item-wrapper-bottom { 
                            padding-bottom: 15px;
                            background-color: #F2F2F2;
                            .number {
                                color: #DC7075;
                            }
                        }

                        }
                        .middle {
                            width: 1px;
                            height: 300px;
                            margin: 30px 0 40px 15px;
                            border-left: 1px dashed #C1C1C1;
                        }
                        .right-part {
                        flex: 4;
                        padding: 10px 20px;
                        display: flex; 
                        flex-direction: column;
                        .title {
                            font-size: 14px; 
                            line-height: 14px;
                            margin-left: 20px;
                            margin: 20px 0 30px 0;
                            
                        }
                        .chart-content {
                            .blank {
                                font: normal normal normal 14px; 
                                line-height: 30px;
                            }
                        }
                        }
                    }
                }
            }

            .table-container {
                .container-top {
                    padding: 10px;
                    display: flex;
                    border-bottom: 1px solid #eeeeee;
                
                    .scenarios-heading-left { 
                      padding: 10px;
                      flex: 50%;
                      max-width: calc(50%);
                      // padding-left: 25px;
                
                      span {
                        color: #333333;
                        font-size: 26px;
                      }
                
                      p {
                        font-size:  16px;
                      }
                    }
                    
                    .scenarios-heading-right { 
                      padding: 10px;
                      flex: 50%;
                      max-width: calc(50%);
                    
                      .btn-scenarios-container-header {
                        float: right;
                    
                        .ie-btn {
                            background-color: #FFFFFF;
                            border-radius: 2px;
                            color: #ca4a04;
                            border: none;
                            margin: 10px;
                    
                            .ie-activity-icon {
                                padding-bottom: 4px;
                                vertical-align: middle;
                                
                            }
                        }
                        .save_ie_btn {
                            margin: 10px;
                        }
                      }
                    }
                
                    .title {
                      flex: 1;
                      font-size: 18px;
                      color: #333;
                      line-height: 35px;
                    }
                    .none-click {
                      font-size: 14px!important;
                      color: #333;
                      pointer-events: none;
                      input{
                          color: #333;
                      }
                    }
                    .saveSOE {
                      color: #ffffff;
                      font-size: 14px;
                    }
                    .ant-btn.ant-btn-primary[disabled], 
                    .ant-btn.ant-btn-primary[disabled]:hover, 
                    .ant-btn.ant-btn-primary[disabled]:focus, 
                    .ant-btn.ant-btn-primary[disabled]:active {
                      color: rgba(0, 0, 0, 0.25) !important;
                      background: #f5f5f5 !important;
                      border-color: #d9d9d9 !important;
                      text-shadow: none !important;
                      box-shadow: none !important;
                    }
                  }
                //   Style for IE Table
                .event-dashboard {
                    .collapse-inner{
                        width:100%;
                        // min-width:2000px;
                        // overflow-y:scroll;
                        box-sizing: content-box;
                        .criteria-list{
                            width: 100%;
                            display:inline-block;
                            border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            background-color: #fff;
                            position: sticky;
                            left: 0px;
                            top: 0px;
                            z-index: 102;
                            vertical-align: middle;
                            height: 100%;
                            .list-columns{
                                display: flex;
                                padding: 5px 0 5px 0px;
                                margin: 5px 0 5px 20px;
                                vertical-align: middle; 
                                div {
                                    flex: 1 1;
                                    color: #333;
                                    font-size: 14px;
                                    font-weight: 600;
                                }

                                
                                // .ant-row .ant-col:first-child {
                                //     padding-left: 18px;
                                // }
                                // .ant-row .ant-col:nth-child(2) {
                                //     padding-left: 45px;
                                // }
                                // .ant-row .ant-col:nth-child(3) {
                                //     padding-left: 40px;
                                // }
                                // .ant-row .ant-col:nth-child(4) {
                                //     padding-left: 40px;
                                // }
                                // .ant-row .ant-col:nth-child(5) {
                                //     padding-left: 58px;
                                // }
                                .ant-row .ant-col:nth-child(6) {
                                    // padding-left: 18px; 
                                }
                                // .ant-row .ant-col:nth-child(7) {
                                //     padding-left: 5px;
                                // }
                                .ant-row .ant-col:last-child {
                                    // padding-left: 87px;
                                    // position: sticky;
                                    // margin-left: 20px;
                                    // right: 0px;
                                    // top: 0px;
                                    // z-index: 102;
                                    // background-color: #fff;
                                }
                            }
                        }
                      
            
                    }
                    .sectionPanel {
        
                        .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header {
                            padding-left: 16px; 
                        }

                        .trial-panelHeader {
                            float: left;
                        }
                        .ant-collapse > .ant-collapse-item {
                            background-color: #EDEDED;
                        }

                        .ant-collapse-arrow {
                            display: block;
                        }

                        .centered-column {
                            text-align: center;
                        }
                        .left-column {
                            text-align: 'left'
                        }
                    }
                }
            }
        }

        .right-insight-container {
            background-color: #f2f2f2;
            // height: 100%;
            .left-insight {

                position: relative;
                right: 0;
                height: 100%;
            }
            .right-icon {
                position: absolute;
                right: 0;
                height: 100%;
                z-index: 8;
            }

        .item-header{
            align-items: center;
            padding: 5px 15px;
            border-bottom: 1px solid #ddd;

            span{
                font-size:16px;
                font-weight: bold;
                color: #333;
            }

            .right-icon{
                float: right;
                min-width:30px;
            }

            span.anticon.anticon-close.right-icon, span.anticon.anticon-history.right-icon{
                transform: translateY(6px);
            }
            .action-wrapper {
                position: absolute;
                right: -2px;
                top: 9px;
                width: 12px;
                height: 20px;
                border: 1px solid #DDDDDD;
                border-radius: 20px 0 0 20px;
                background-color: #fff;
                border-right: 1px solid #fff;
              }
            .action-title {
                position: absolute;
                right: -4px;
                top: 2px;
                font-size: 14px;
                padding-left: 8px;
                height: 50px;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                color:#CA4A04;
                span {
                    font-size: 13px;
                }
              }
        }


        .item-header .ant-row{
            .action-wrapper {
                position: absolute;
                right: -15px;
                top: 4px;
                width: 12px;
                height: 20px;
                border: 1px solid #DDDDDD;
                border-radius: 20px 0 0 20px;
                background-color: #fff;
                border-right: 1px solid #fff;
              }
            .action-title {
                position: absolute;
                right: -17px;
                top: -3px;
                font-size: 14px;
                padding-left: 8px;
                height: 50px;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                color:#CA4A04;
                span {
                    font-size: 13px;
                }
            }
        }
        .insight-box-wrapper {
            background-color: #F8F8F8;
            width: 100%;
            padding: 0 10px;
            height: 100%;
            min-height: 500px;
            // height: 530px;
            // overflow: scroll;
        }
        .insight-box {
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            margin: 10px 0;
            padding: 10px;
            .head {
                color:#999999;
                font-size: 12px;
                font-weight: 500;
            }
            .content {
                color: #404042;
                margin: 20px 0;
                font-size: 14px;
    
            }
            .button-area {
                color:#0084A9;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                padding-right: 8px;
                .button-left, .button-right {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }

        }

        .pd-cost-chart-wrapper {
            .ant-collapse-content-box {
                padding: 16px 0px 0px 0px!important;
              }
        }
    }
}

// .ant-table-cell {
//     border: 1px solid green;
// }