.edit-table-wrapper {
  .ant-collapse-content-box {
    padding: 16px 0 16px 16px!important;
  }
  tr .operation {
    position: sticky;
    right: 0px;
    top: 0px;
    z-index: 99;
    background-color: #fff;
    padding-right: 16px!important;
  }
  tr .not-show {
    display: none;
  }
  .ant-collapse-header {
    .ant-collapse-extra {
      position: sticky;
      right: 16px;
      top: 0px;
      z-index: 99;
    }
  }
  a.ant-typography, .ant-typography a {
    cursor: default!important;
  }
  .criteria-ellipsis {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: all;
  }
  .criteria-ellipsis-large {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: all;
  }
}

// div .ant-modal-root .ant-modal-wrap {
//   margin-top: calc(10% - 10px) !important;
//   top: 10px !important; 
// }

.ant-table-cell .detail-cell-value-wrap {
  // padding-top: 8px;
  // padding-bottom: 8px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

a.ant-typography,
.ant-typography a {
  color: #333;
  span {
    width: 100%;
  }
}

a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #000000d9;
}

.ant-table-cell {
  padding: 16px 5px !important;
  .ant-form-item {
    margin-bottom: 0!important;
  }
}

.ant-table-cell > span {
  span.anticon-close {
    color: red;
    font-weight: bold;
  }
  span.anticon-check {
    color: green;
    font-weight: bold;
  }
}

span.popover-action {
  cursor: pointer;
}

span.anticon.anticon-close.ant-modal-close-icon {
  color: black;
}

.ant-btn-primary {
  background-color: #ca4a04;
  border-color: #ca4a04;
}

.ant-btn-primary.login__button {
  background-color: black;
  border-color: black;
  height: 40px;
}
.sub-criteria-wrapper {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #fbfbfb;

  .ant-input,
  .sub-row-non-editable {
    margin-right: 20px;
    color: #333;
  }

  .actions {
    margin-left: 5px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    .anticon {
      cursor: pointer;
    }
    .anticon.anticon-check {
      margin-right: 5px;
    }
  }

  .serial-number{
    padding-left: 45px;
    padding-right: 60px;
    color: #333;
  }
}
.sub-criteria-wrapper:hover, .sub-criteria-wrapper:focus {
  background-color: #e7e7e7;
}
.sub-row-non-editable {
  flex: 1;
  padding: 4px 11px;
  cursor: pointer;
}

.criteriaDetailModal {
    left: 0% !important;
    .ie-configure-title {
        text-align: left;
        font-size:  24px;
        color: #000000;
        padding: 5px;
    }
    
    .endpoint-modal-filed {
        background: #F8F8F8;
        border: 1px solid #DDDDDD;
        padding: 5px;
        margin-bottom: 5px;
        .informed-consent-label {
            text-align: left;
            font-size: 24px;  
            color: #000000;
            font-weight: bold;
        }
        .procedure-label {
            text-align: left;
            font-size: 16px; 
            color: #333333;
            font-weight: 600;
        }
    
        .label-header {
            font-size: 11px; 
            color: #383A3C;
        }
        .label-values {
            font-size:  22px; 
            color: #000000;
    
        }
    }
    
    .ie-criteria-selector {
        text-align: left;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        .header {
          display: flex;
          font-size: 14px;
          .criteria {
            width: 200px;
          }
          .history {
            width: 320px;
            .top {

            }
            .bottom {
              // font-style: italic;
            }
          }
          .modifier {
            width: 320px;
          }

        }
    }

    .cancel-btn {
        text-align: center;
        font-size: 16px;
        color: #333333;
        background: #FFFFFF;
        margin-right: 10px;
        border: none;
    }
    .create-btn {
        text-align: center;
        font-size:  16px;
        color: #FFFFFF;
        background: #CA4A04;
    }
}

.ant-input{
  font-size: 18px;
  color: #333;
}
.ant-modal-footer{
  .ant-btn-primary{
    font-size: 16px;
  }
}

.ant-form-item-control {
  position: relative;
  top: 11px;
  height: 54px;
  line-height: 54px;
  zoom: 1;
  .ant-form-item-control-input {
    height: 30px;
    line-height: 30px;
    .ant-form-item-control-input-content {
      display: inline-block;
      position: absolute;
      left: 0;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    div {
      display: inline-block;
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
  }

  .none-click {
  pointer-events: none
  }