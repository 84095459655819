
.soa-activities-container {
    background-color: #F2F2F2;
    padding: 0% 0% 4% 6%;

    .scenarios-actions-container {
        display: flex;
        flex-wrap: wrap;
        font-size: 30px; 

        .item-header{
            align-items: center;
            padding: 5px 15px;
            border-bottom: 1px solid #ddd;

            span{
                font-size:16px;
                font-weight: bold;
                color: #333;
            }

            .right-icon{
                float: right;
                min-width:30px;
            }

            span.anticon.anticon-close.right-icon, span.anticon.anticon-history.right-icon{
                transform: translateY(6px);
            }
            .action-wrapper {
                position: absolute;
                right: -2px;
                top: 9px;
                width: 12px;
                height: 20px;
                border: 1px solid #DDDDDD;
                border-radius: 20px 0 0 20px;
                background-color: #fff;
                border-right: 1px solid #fff;
              }
            .action-title {
                position: absolute;
                right: -4px;
                top: 2px;
                font-size: 14px;
                padding-left: 8px;
                height: 50px;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                color:#CA4A04;
                span {
                    font-size: 13px;
                }
              }
        }


        .item-header .ant-row{
            .action-wrapper {
                position: absolute;
                right: -15px;
                top: 4px;
                width: 12px;
                height: 20px;
                border: 1px solid #DDDDDD;
                border-radius: 20px 0 0 20px;
                background-color: #fff;
                border-right: 1px solid #fff;
              }
            .action-title {
                position: absolute;
                right: -17px;
                top: -3px;
                font-size: 14px;
                padding-left: 8px;
                height: 50px;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                color:#CA4A04;
                span {
                    font-size: 13px;
                }
            }
        }
        .insight-box-wrapper {
            background-color: #F8F8F8;
            width: 100%;
            padding: 0 10px;
            height: 100%;
            min-height: 500px;
            // height: 530px;
            // overflow: scroll;
        }
        .insight-box {
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            margin: 10px 0;
            padding: 10px;
            .head {
                color:#999999;
                font-size: 12px;
                font-weight: 500;
            }
            .content {
                color: #404042;
                margin: 20px 0;
                font-size: 14px;
    
            }
            .button-area {
                color:#0084A9;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                padding-right: 8px;
                .button-left, .button-right {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
        

        .summary-container {
            display: flex;
            .cost-chart-wrapper {
                justify-content: center !important;
            }
            .chart-wrapper {
                display: flex;
                flex-direction: column;
                margin: 0px 35px 35px 0;
                border-radius: 10px;
                background-color:#fff;
                border: 1px solid rgba(49, 57, 63, 0.2);

                .cost-header-wrapper {
                    position: absolute;
                    top: 0
                }
               
            }
            .left-chart {
                margin: 0px 70px 0 0;
            }
             
            .patient-burden-container {
                background-color: white;
                p {
                    font-size: 16px;
                    font-weight: 600;
                } 
            }

            .dct-impact-on-patient-burden {
                .chart-container {
                    display: flex;
                    justify-content: space-around;
                    .left-part {
                    display: flex;
                    width: 159px;
                    justify-content: space-around;
                    flex-direction: column;
                    padding: 0 0 0 12px;
                    .item-wrapper {
                        // flex: 1;
                        width: 152px;
                        padding: 10px;
                        font: normal normal bold 24px/17px;
                        .text {
                            color: #797979;
                            font-size: 14px;
                            font-weight: 500;
                        }
                        .number {
                            color: #6F2849;
                            font-size: 24px;
                            font-weight: 700;
                            margin-bottom: 5px;
                        }
                    }
                    .item-wrapper-top {
                        background-color: #F2F2F2;
                        .number {
                            color: #295477;
                        }
                    }
                    .item-wrapper-bottom {
                        border: 1px dashed #707070;
                        .number {
                            color: #FFB600;
                        }
                    }
        
                    }
                    .middle {
                        width: 1px;
                        height: 350px;
                        margin: 30px 0 20px 15px;
                        border-left: 1px dashed #C1C1C1;
                    }
                    .right-part {
                    flex: 1;
                    padding: 10px;
                    display: flex;
                    // justify-content: space-around;
                    flex-direction: column;
                    .title {
                        font: normal normal bold 14px; 
                        line-height: 14px;
                        padding-left: 20px;
                        margin: 20px 0 30px 0;
                    }
                    .chart-content {
                        .blank {
                            font: normal normal normal 14px ; 
                            line-height: 30px;
                            padding-left: 20px;
                            padding-right: 10px;
                        }
                    }
                    }
                }
            }
        }
        .tab-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .tab{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                margin-top: 2px;
                cursor: pointer;
            }
            .tab-indicator{
                position: absolute;
                display: flex;
                justify-content: center;
                top: -10px;
                height: 2px;
                width: 60%;
            }
            .tab-indicator.active {
                background-color: #E72B33;
              }
              
              .tab-indicator.inactive {
                background-color: #7D7D7D;
              }
              
            .view-all-button {
                font-size: 16px;
                margin-right: -10px;
                color: #E72B33;
                font-weight: 700;
                cursor: pointer;
                margin-left: 100px; 
            }
        }

        .scenarios-heading-left { 
        padding: 10px;
        flex: 50%;
        max-width: calc(50%);
        padding-left: 25px;

        span {
            color: #333333;
            font-size: 26px;
        }

        p {
            font-size:  16px;
        }
        
        }
        
        .scenarios-heading-right { 
        padding: 10px;
        flex: 50%;
        // max-width: calc(50%);

        .btn-scenarios-container-header {
            float: right;

            .soa-btn {
                
                background-color: #FFFFFF;
                border-radius: 2px;
                color: #ca4a04;
                border: none;
                margin: 10px;

                .soa-activity-icon {
                    padding-bottom: 4px;
                }
            }

            .save_soa_btn {
                margin: 10px;
            }
        }
        }
    }
    .right-insight-container {
        background-color: #f2f2f2;
        // height: 100%;
        .left-insight {
            // position: fixed;
            // position: absolute;
            // right: 50px;
            // height: 100%;
            // max-width: 300px;
            // min-width: 300px;
            // z-index: 8;

            position: relative;
            right: 0;
            height: 100%;
        }
        .right-icon{
            position: absolute;
            right: 0;
            height: 100%;
            z-index: 8;
        }
    }
}


.configure-title{
    justify-content: center;
    span{
        font-size: 18px;
        color:#333;
    }
}
.configure-desc{
    justify-content: center;
    span{
        font-size: 15px;
        color:#999;
    }
}

.soaModal {
    margin-top: 200px;
}

.modal-filed{
    .label{
        text-align: right;
        padding: 5px 10px;
        transform: translateY(5px);
        span{
            color: #333;
        }
    }

    .input-number{
        padding: 5px 10px;
        input{
            color: #333;
            font-size: 14px;
        }
    }

    .anticon.anticon-down.ant-input-number-handler-down-inner{
        color:#666;
    }

    .ant-input-number .ant-input-number-handler-wrap {
        opacity: 1;
        border-left: unset;
    }

    .ant-input-number .ant-input-number-handler-down{
        border-top: unset;
    }

    .ant-input-number.ant-input-number {
        width: 70px;
    }

    .input-number-error{
        text-align: center;
        padding: 5px 10px;
        transform: translateY(5px);
        padding: 5px 5px;
        color: #d04a02;
    }
    .ant-btn.ant-btn-primary[disabled], 
    .ant-btn.ant-btn-primary[disabled]:hover, 
    .ant-btn.ant-btn-primary[disabled]:focus, 
    .ant-btn.ant-btn-primary[disabled]:active {
      color: rgba(0, 0, 0, 0.25) !important;
      background: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
      text-shadow: none !important;
      box-shadow: none !important;
      cursor: default!important;
    }
}
