
.ie-library-container {
    background-color: #F2F2F2;
    padding: 0% 0% 0% 6%;
    .scenarios-actions-container {
        display: flex;
        flex-wrap: wrap;
        font-size: 30px; 
        .left-content-container {
            .summary-container {
                display: flex;
                justify-content: space-around;
                border-bottom: 1px solid #00000029;
                .chart-wrapper {
                    display: flex;
                    flex-direction: column;
                    margin: 0px 0px 30px 40px;
                    border-radius: 10px;
                    background-color:#fff;
                    border: 1px solid rgba(49, 57, 63, 0.2);
                    .chart-header {
                        padding: 10px 20px;
                        color: #333333;
                        border-bottom: 2px solid #eee;
                        display: flex;
                        justify-content: space-between;
                        .title {
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }
                    .chart-container {
                        padding: 10px 20px;
                        height: 300px;
                        font-size: 16px;
                        .description {
                            font-size: 16px;
                            height: 28px;
                            color: #E53500;
                            margin: 0;
                        }
                        .legend {
                            .item-desc {
                                width: 70%;
                                line-height: 15px;
                                display: flex;
                                justify-content: space-between;
                                .item {
                                    width: 120px;
                                }
                                span{
                                    font-size:12px;
                                    color: #333;
                                }
            
                                .bar-item{
                                    display:inline-block;
                                    content: "";
                                    width: 7px;
                                    height: 7px;
                                    border-radius: 5px;
                                    margin-right: 5px;
                                    margin-left: 5px;
                                }
            
                                .item1{
                                    background-color: #D04A02;
                                    // background-color: #6F2849;
                                }
            
                                .item2{
                                    background-color: #EB8C00;
                                    // background-color: #9C3F5D;
                                }
            
                                .item3{
                                    background-color: #FFB600;
                                    // background-color: #C8586C;
                                }
            
                                .item4{
                                    background-color: #295477;
                                    // background-color: #DC7075;
                                }
                            }
                        }
                        .center-box {
                            display: flex; 
                            height: 230px;
                            .copy-width {
                                width: 65%;
                                margin-top: 30px;
                            }
                            .pie-width {
                                width: 35%;
                            }
                            .label {
                                max-width: 300px; 
                            }
                            .detail {
                                color:#CA4A04;
                                font-weight: 500;
                                font-size: 14px;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                }
                
            }
            .table-container {
                .container-top {
                    padding: 10px;
                    display: flex;
                    border-bottom: 1px solid #eeeeee;
                
                    .scenarios-heading-left { 
                      padding: 10px;
                      flex: 50%;
                      max-width: calc(50%);
                      // padding-left: 25px;
                
                      span {
                        color: #333333;
                        font-size: 26px;
                      }
                
                      p {
                        font-size:  16px;
                      }
                    }
                    
                    .scenarios-heading-right { 
                      padding: 10px;
                      flex: 50%;
                      max-width: calc(50%);
                    
                      .btn-scenarios-container-header {
                        float: right;

                    
                        .ie-btn {
                            background-color: #FFFFFF;
                            border-radius: 2px;
                            color: #ca4a04;
                            border: none;
                            margin: 10px;
                    
                            .ie-activity-icon {
                                vertical-align: middle;
                                padding-bottom: 4px;
                            }
                        }
                        .save_ie_btn {
                            margin: 10px;
                        }
                      }
                    }
                
                    .title {
                      flex: 1;
                      font-size: 18px;
                      color: #333;
                      line-height: 35px;
                    }
                    .none-click {
                      font-size: 14px!important;
                      color: #333;
                      pointer-events: none;
                      input{
                          color: #333;
                      }
                    }
                    .saveSOE {
                      color: #ffffff;
                      font-size: 14px;
                    }
                    .ant-btn.ant-btn-primary[disabled], 
                    .ant-btn.ant-btn-primary[disabled]:hover, 
                    .ant-btn.ant-btn-primary[disabled]:focus, 
                    .ant-btn.ant-btn-primary[disabled]:active {
                      color: rgba(0, 0, 0, 0.25) !important;
                      background: #f5f5f5 !important;
                      border-color: #d9d9d9 !important;
                      text-shadow: none !important;
                      box-shadow: none !important;
                    }
                  }
                //   Style for IE Table
                .event-dashboard {
                    .collapse-inner{
                        // width:100%;
                        min-width:2400px;
                        // overflow-y:scroll;
                        box-sizing: content-box;
                        .criteria-list{
                            width: 100%;
                            display:inline-block;
                            border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            background-color: #fff;
                            position: sticky;
                            left: 0px;
                            top: 0px;
                            z-index: 102;
                            .list-columns{
                                display: flex;
                                padding: 5px 0 5px 0px;
                                margin: 5px 0 5px 20px;
                                vertical-align: middle;
                                div {
                                    flex: 1 1;
                                    color: #333;
                                    font-size: 14px;
                                    font-weight: 600;
                                }
                                .ant-row .ant-col:first-child {
                                    // padding-left: 18px;
                                }
                                .ant-row .ant-col:nth-child(2) {
                                    padding-left: 20px;
                                }
                                .ant-row .ant-col:nth-child(3) {
                                    padding-left: 21px;
                                }
                                .ant-row .ant-col:nth-child(4) {
                                    padding-left: 20px;
                                }
                                .ant-row .ant-col:nth-child(5) {
                                    padding-left: 22px;
                                }
                                .ant-row .ant-col:nth-child(6) {
                                    padding-left: 22px;
                                }
                                .ant-row .ant-col:nth-child(7) {
                                    padding-left: 22px;
                                }
                                .ant-row .ant-col:nth-child(8) {
                                    padding-left: 25px;
                                }
                                .ant-row .ant-col:last-child {
                                    padding-left: 15px;
                                    position: sticky;
                                    margin-left: 20px;
                                    right: 0px;
                                    top: 0px;
                                    z-index: 102;
                                    background-color: #fff;
                                }
                            }
                        }
                        .sectionPanel {

                            .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header {
                                padding-left: 36px;
                            }
                            .trial-panelHeader {
                                float: left;
                            }
                            .ant-collapse > .ant-collapse-item {
                                background-color: #e7e7e7;
                            }
                        }
            
                    }
                }
            }
    
            .scenarios-heading-left { 
            padding: 10px;
            flex: 50%;
            max-width: calc(50%);
            padding-left: 25px;
    
            span {
                color: #333333;
                font-size: 26px;
            }
    
            p {
                font-size:  16px;
            }
            
            }

        }
        .right-insight-container {
            background-color: #f2f2f2;
            // height: 100%;
            .left-insight {
                // position: fixed;
                // position: absolute;
                // right: 50px;
                // height: 100%;
                // max-width: 300px;
                // min-width: 300px;
                // z-index: 8;

                position: relative;
                right: 0;
                height: 100%;
            }
            .right-icon {
                position: absolute;
                right: 0;
                height: 100%;
                z-index: 8;
            }

        .item-header{
            align-items: center;
            padding: 5px 15px;
            border-bottom: 1px solid #ddd;

            span{
                font-size:16px;
                font-weight: bold;
                color: #333;
            }

            .right-icon{
                float: right;
                min-width:30px;
            }

            span.anticon.anticon-close.right-icon, span.anticon.anticon-history.right-icon{
                transform: translateY(6px);
            }
            .action-wrapper {
                position: absolute;
                right: -2px;
                top: 9px;
                width: 12px;
                height: 20px;
                border: 1px solid #DDDDDD;
                border-radius: 20px 0 0 20px;
                background-color: #fff;
                border-right: 1px solid #fff;
              }
            .action-title {
                position: absolute;
                right: -4px;
                top: 2px;
                font-size: 14px;
                padding-left: 8px;
                height: 50px;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                color:#CA4A04;
                span {
                    font-size: 13px;
                }
              }
        }


        .item-header .ant-row{
            .action-wrapper {
                position: absolute;
                right: -15px;
                top: 4px;
                width: 12px;
                height: 20px;
                border: 1px solid #DDDDDD;
                border-radius: 20px 0 0 20px;
                background-color: #fff;
                border-right: 1px solid #fff;
              }
            .action-title {
                position: absolute;
                right: -17px;
                top: -3px;
                font-size: 14px;
                padding-left: 8px;
                height: 50px;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                color:#CA4A04;
                span {
                    font-size: 13px;
                }
            }
        }
        .insight-box-wrapper {
            background-color: #F8F8F8;
            width: 100%;
            padding: 0 10px;
            height: 100%;
            min-height: 500px;
            // height: 530px;
            // overflow: scroll;
        }
        .insight-box {
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            margin: 10px 0;
            padding: 10px;
            .head {
                color:#999999;
                font-size: 12px;
                font-weight: 500;
            }
            .content {
                color: #404042;
                margin: 20px 0;
                font-size: 14px;
    
            }
            .button-area {
                color:#0084A9;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                padding-right: 8px;
                .button-left, .button-right {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }

        }

        
        .scenarios-heading-right { 
        padding: 10px;
        flex: 50%;
        // max-width: calc(50%);

        .btn-scenarios-container-header {
            float: right;

            .ie-btn {
                background-color: #FFFFFF;
                border-radius: 2px;
                color: #ca4a04;
                border: none;
                margin: 10px;

                .ie-activity-icon {
                    padding-bottom: 4px;
                }
            }

            .save_ie_btn {
                margin: 10px;
            }
        }
        }
    }
}


.configure-title{
    justify-content: center;
    span{
        font-size: 18px;
        color:#333;
    }
}
.configure-desc{
    justify-content: center;
    span{
        font-size: 15px;
        color:#999;
    }
}

// .ant-modal-wrap {
//     margin-top: 170px !important;
// }

.modal-filed{
    .label{
        text-align: right;
        padding: 5px 10px;
        transform: translateY(5px);
        span{
            color: #333;
        }
    }

    .input-number{
        padding: 5px 10px;
        input{
            color: #333;
            font-size: 14px;
        }
    }

    .anticon.anticon-down.ant-input-number-handler-down-inner{
        color:#666;
    }

    .ant-input-number .ant-input-number-handler-wrap {
        opacity: 1;
        border-left: unset;
    }

    .ant-input-number .ant-input-number-handler-down{
        border-top: unset;
    }

    .ant-input-number.ant-input-number {
        width: 70px;
    }

    .input-number-error{
        text-align: center;
        padding: 5px 10px;
        transform: translateY(5px);
        padding: 5px 5px;
        color: #d04a02;
    }
}

.detail-table-wrapper.ant-popover {
    .ant-popover-inner-content {
        padding: 0px;
    }
    .ant-table-thead .ant-table-cell {
        font-weight: 600;
        background-color: #fff !important;
        color: rgba(0, 0, 0, 0.85) !important;
    }

    .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
        padding: 5px 15px !important;
    }
}
