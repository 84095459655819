
.criteriaDetailModal {
    left: 0% !important;
    .ie-configure-title {
        text-align: left;
        font-size:  24px;
        color: #000000;
        padding: 5px;
    }
    
    .endpoint-modal-filed {
        background: #F8F8F8;
        border: 1px solid #DDDDDD;
        padding: 5px;
        margin-bottom: 5px;
        .informed-consent-label {
            text-align: left;
            font-size: 24px;  
            color: #000000;
            font-weight: bold;
        }
        .procedure-label {
            text-align: left;
            font-size: 16px; 
            color: #333333;
            font-weight: 600;
        }
    
        .label-header {
            font-size: 11px; 
            color: #383A3C;
        }
        .label-values {
            font-size:  22px; 
            color: #000000;
    
        }
    }
    
    .ie-criteria-selector {
        text-align: left;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        .header {
          display: flex;
          font-size: 14px;
          .criteria {
            width: 200px;
          }
          .history {
            width: 320px;
            .top {

            }
            .bottom {
              // font-style: italic;
            }
          }
          .modifier {
            width: 320px;
          }

        }
    }

    .cancel-btn {
        text-align: center;
        font-size: 16px;
        color: #333333;
        background: #FFFFFF;
        margin-right: 10px;
        border: none;
    }
    .create-btn {
        text-align: center;
        font-size:  16px;
        color: #FFFFFF;
        background: #CA4A04;
    }
}