@import '../../styles/variables.scss';

.chart-summary-container{
    padding-bottom: 30px;
    .ant-table-container .ant-table-content .ant-table-thead > tr > th {
        background-color: white !important;
        color: black !important;
    }
    .configure-chart{
        display: flex;
        flex-direction: column;
        margin: 30px 0px 0px 40px;
        width: calc(91.66666667% + 30px); 
        border-radius: 10px;
        background-color:#fff;
        .chart-header {
            padding: 10px 20px;
            color: #333333;
            justify-content: space-between;
            font-size: 16px;
            font-weight: bold;
        }
        .description {
            font-size: 12px;
            font-weight: 500;
        }
        .title{
            font-weight: 700;
        }
    }
    .chart-wrapper {
        display: flex;
        flex-direction: column;
        margin: 30px 0px 0px 40px;
        border-radius: 10px;
        background-color:#fff;
        .chart-header {
            padding: 10px 20px;
            color: #333333;
            border-bottom: 2px solid #eee;
            display: flex;
            justify-content: space-between;
            .title {
                font-size: 16px;
                font-weight: 700;
            }
            .legend {
                font-size: 10px;
                color: "#787878";
                padding-top: 8px;
                .circle {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    margin: 0 10px 0 20px;
                }
            }
        }
    }
    .dct-impact-on-patient-burden {
        .chart-container {
            display: flex;
            justify-content: space-around;
            .left-part {
            display: flex;
            width: 159px;
            justify-content: space-around;
            flex-direction: column;
            padding: 0 0 0 12px;
            .item-wrapper {
                 // flex: 1;
                 width: 152px;
                 padding: 10px;
                font: normal normal bold 24px/17px;
                .text {
                    color: #797979;
                    font-size: 14px;
                    font-weight: 500;
                }
                .number {
                    color: #6F2849;
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 5px;
                }
            }
            .item-wrapper-top {
                background-color: #F2F2F2;
                .number {
                    color: #295477;
                    // color: #6F2849;
                }
            }
            .item-wrapper-bottom {
                border: 1px dashed #707070;
                .number {
                    color: #FFB600;
                    // color: #FAD49C;
                }
            }

            }
            .middle {
                width: 1px;
                height: 350px;
                margin: 30px 0 20px 15px;
                border-left: 1px dashed #C1C1C1;
            }
            .right-part {
            flex: 1;
            padding: 10px;
            display: flex;
            // justify-content: space-around;
            flex-direction: column;
            .title {
                font: normal normal bold 14px; 
                line-height: 14px;
                padding-left: 20px;
                margin: 20px 0 30px 0;
            }
            .chart-content {
                .blank {
                    font: normal normal normal 14px; 
                    line-height: 30px;
                    padding-left: 20px;
                    padding-right: 10px;
                }
            }
            }
        }
    }
    .patient-burden-by-activity-category {
        .chart-header {
            .title {
                
            }
        }
        .chart-container {
            position: relative;
            top: calc(100% - 441px);
        }
    }

    .count-soa-by-activity-category {
        .chart-container {
            position: relative;
            top: calc(100% - 395px);
        }
    }
}
.configure-charts-modal {
    .ant-modal-content {
        border-radius: 15px!important;
    }
    .ant-modal-header {
        border-radius: 15px!important;
        .ant-modal-title {
            font-size: 24px;
        }
    }
    .ant-modal-body {
        padding: 0;
    }

    .chart-list {
        padding: 20px;

        &.selected-charts {
            background-color: #f5f5f5;
            border-top: 1px solid #DDDDDD;
            border-bottom: 1px solid #DDDDDD;

            .selected-charts-title {
                font-size: 18px;
            }
        }

        &.available-charts {
            border-bottom: 1px solid #000;
        }

        & > ul {
            margin: 15px 0 0;
            list-style: none;
            padding: 0;

            & > li {
                display: block;
                position: relative;
                border-top: 1px solid #dddddd;
                padding: 5px 0;

                span {
                    display: block;

                    &.chart-icon {
                        display: inline-block;
                        font-weight: 800;
                        font-size: 1.2em;
                        color: #999999;
                        margin-right: 10px;
                    }
                    &.chart-title {
                        font-weight: 500;
                        font-size: 16px;
                    }
                }

                .chart-btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    cursor: pointer;
                    display: grid;
                    align-items: center;
                    width: 70px;
                    text-align: center;
                    color: #D04A02;
                }
            }
        }
    }

    .submit-complete-btn {
        color: #CA4A04;
        border-color: #CA4A04;
        font-weight: 500;
        border-width: 1.5px;
    }
}