@import "../../styles/variables.scss";
.table-complexity {
  background-color: #DDDDDD;
}
.table-complexity:hover {
  background-color: #DDDDDD;
}
.ant-modal-wrap .mvp-modal.ant-modal {
  width: 700px !important;
  .configure-title { 
    justify-content: left;
    span {
      font-size: 18px;
      color: #333333; 
      font-weight: 500;
    }
   
  }
}
.scenarios-container {
  padding: 25px 0 50px 0;
  .required {
    font-size: 10px;
    color: #d04a02
  }
  .container-top {
    margin-bottom: 20px;
    font-size: 32px;
    color: #090000;
    span {
      color: #333333;
      font-size: 12px;
    }
    .count {
      font-size: 18px;
    }
  }
  .module-wrapper {
    .module-item {
      margin-bottom: 20px;
      font-size: 14px;
      border-radius: 2px;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px #00000029;
      .ant-btn {
        font-size: 14px;
      }
      .complete-module-btn {
        margin-right: 10px;
      }
      .ant-btn-link {
        color: $theme-color;
        font-size: 12px;
      }
      .ant-btn-dangerous {
        color: $theme-color;
        border-color: $theme-color;
        font-size: 12px;
      }
      .top {
        display: flex;
        padding: 15px 10px;
        border-bottom: 1px solid #eaeaea;
        align-items: center;
        min-height: 57px;
        .scenario-status {
          margin-left: 10px;
          font-size: 10px;
          // text-transform: uppercase;
          color:#000000;

          .my_icon {
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-right: 5px;
            border-radius: 3px;

            &.in-progress-icon {
              background-color: #FFC107;
            }
            &.complete-icon {
              background-color: #18D300;
            }
          }
        }

        .module-name {
          font-size: 16px;
          color: #090000;
          flex: 1;
          padding-left: 10px;
        }
      }
      .bottom {
        padding: 15px 20px;
        color: #999999;
      }
      .scenarios-list-container {
        display: flex;
        .count {
          padding: 20px 0;
          border-right: 1px solid #eaeaea;
          border-bottom: 1px solid #eaeaea;
          width: 100px;
          text-align: center;
          font-size: 14px;
          color: #000000;

          span {
            font-size: 42px;
            font-weight: bold;
          }
        }
        .scenarios-list {
          width: 100%;
          .item-wrapper {
            border-bottom: 1px solid #eaeaea;
          }
          .item-wrapper.average-item {
            border-bottom: 1px solid transparent;
          }
          .scenario-item.scenario-header {
            font-size: 16px;
            border-bottom: 1px solid #eaeaea;
            .title {
              border-right: 1px solid transparent;
              display: flex;
              align-items: center;
              font-size: 20px;
              // text-transform: uppercase;
              color: #000;
              font-weight: bold;
            }
          }
          .rationale-content {
            padding-top: 10px;
            p {
              font-size: 16px;
              color: #666666;
              padding: 0;
              margin: 0;
            }
            span {
              font-size: 14px;
              color: #000000;
              span span {
                &:nth-child(1) {
                  pointer-events: none;
                }
              }
            }
          }
          .scenario-item {
            display: flex;
            padding: 15px 20px;

            .title.average-title {
              color: #333333;
              font-size: 12px;
            }

            .title {
              padding-right: 20px;
              border-right: 1px solid #eaeaea;
              width: 300px;
              .scenario-item-wrapper{
                display: flex;
                align-items: center;
                .scenario_status-wrapper {
                  display: flex;
                  padding-left: 10px;
                }
                .scenario_status {
                  font-size: 12px;
                  color: #000000;
                  // text-transform: uppercase;
                  padding-left: 5px;
                } 
              }
              .trial_name {
                margin-bottom: 5px !important;
                font-weight: 600;
                font-size: 22px;
                color:#333333;
                span span {
                  &:nth-child(1) {
                    pointer-events: none;
                  }
                }
              }
              .trial_des {
                font-size: 14px;
                color: #999999;
                span span {
                  &:nth-child(1) {
                    pointer-events: none;
                  }
                }
              }
            }

            .title:hover, .title:focus {
              p{
                color: #CA4A04;
              }
            }

            .item-values {
              display: flex;
              padding-left: 20px;
              flex: 1;
              &.col-names {
                .col-title {
                  font-size: 18px;
                  font-weight: bold;
                  // text-transform: uppercase;
                  color:#000000;
                  padding: 0;
                  margin: 0;
                }
                .col-description {
                  font-size: 14px; 
                  font-weight: 400;
                  color:#4E4E4E;
                  padding: 0;
                  margin: 0;
                }
                .info {
                  color: #4390DF;
                }
              }
              div {
                flex: 1;
                font-size: 20px;
                font-weight: 600;
                color:#414C4E;
                padding-right: 10px;
                span {
                  padding-left: 5px;
                }
              }
              .column {
                display: block;
                font-size: 10px;
                font-weight: 400;
              }
              .complexity {
                font-size: 16px; 
                border-radius: 4px;
                color: #1C7EE3;
                text-decoration: underline;
                padding: auto;
                width: auto;
                height: auto;
                // width: 130px;
                // height: 20px;
                display: inline-block;
                vertical-align: center;
                text-align: center;
                color: #1C7EE3;
                &.LOW {
                  background: #CCEEDA;
                }
                &.MEDIUM {
                  background: #FFECB4;
                }
                &.HIGH {
                  background: #EDC1C1;
                }
                &.INCOMPLETE {
                  background: #BBBBBB;
                  text-decoration: none;
                  cursor: default;
                }
              }
              .status {
                display: block;
                font-size: 20px;
                &.HIGH {
                  color: #C33232;
                }
                &.LOW {
                  color: #00A947;
                }
                &.MEDIUM {
                  color: #0084A9;
                }
              }
            }
            .item-values.average {
              div {
                font-weight: 400;
              }
            }
          }
        }
      }

      .item-wrapper.average-item {
        border-bottom: 1px solid transparent;
        background-color: #F5F5F5;

        .scenario-item {
          display: flex;
          padding: 15px 20px;
        }
        .average-title {
          padding-right: 8px; 
          font-size: 16px;
          color: #333333;

          .material-info{
            transform: translateY(-3px);
            font-size: 14px;
            color: #000000;
          }
        }

        .item-values.average {
          display: flex;
          flex: 1;
          padding-left: 20px;
          div {
            flex: 1;
            font-size: 18px;
            color: #333333;
          }
        }
      }
    }
  }

  .scenario-dashboard {
    display: flex;
    .create-btn-wrapper {
      background-color: #ffffff;
      padding: 26px;

      .create-btn {
        border: 1px dashed #000000;
        padding: 100px 0;
        width: 188px;
        text-align: center;
        span {
          color: #ca4a04;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .create-btn:hover {
        cursor: pointer;
      }
    }
    .columns {
      .title {
        height: 120px;
      }
      .col-item {
        padding: 10px 20px;
        background-color: #414c4e;
        color: #ffffff;
        position: relative;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid #ffffff;
      }
      .col-item:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-left: 4px solid transparent;
        border-right: 4px solid #fff;
        border-bottom: 4px solid transparent;
      }
    }

    .scenario-list {
      display: flex;
      box-shadow: 0px 0px 6px #00000029;
      .title {
        height: 120px;
      }
      .average-title {
        background-color: #eaeaea;
      }
      .btn-wrapper {
        background-color: #ffffff;
        padding: 12px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .view-btn {
          cursor: pointer;
          background-color: #414c4e;
          color: #ffffff;
          padding: 6px 0;
          width: 142px;
        }
      }

      .scenario {
        flex: 1;
        background-color: #ffffff;
        border-right: 1px solid #eaeaea;

        text-align: center;
        .title {
          padding: 10px 20px;
          span {
            color: #999999;
            font-size: 12px;
            &:first-child {
              font-size: 20px;
              color: #4d4f53;
              font-weight: 600;
            }
          }
        }
        .col-value {
          padding: 10px 20px;
          height: 75px;
          background-color: #f8f8f8;
          // padding: 12px 20px;
          border-bottom: 1px solid #ffffff;
          i {
            font-style: normal;
            font-size: 12px;
            font-weight: 600;
          }
          .percent {
            font-size: 22px;
            font-weight: 600;
            color: #414c4e;
            position: relative;
          }
          .percent::after {
            content: "";
            position: absolute;
            right: -10px;
            top: -5px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
          }
          &.LOW {
            .percent::after {
              background-color: #00a947;
            }
            i {
              color: #00a947;
            }
          }
          &.HIGH {
            .percent::after {
              background-color: #c33232;
            }
            i {
              color: #c33232;
            }
          }
          &.MEDIUM {
            .percent::after {
              background-color: #0084a9;
            }
            i {
              color: #0084a9;
            }
          }
        }
        .average-value {
          background-color: #f8f8f8;
        }
      }
    }
  }
}

.sectionPanel {
  .low {
    width: 97px;
    height: 20px;
    background: #CCEEDA;
    border-radius: 4px;
    font-size: 10px;
    padding: 5px;
  }
  .medium {
    width: 97px;
    height: 20px;
    background: #FFECB4;
    border-radius: 4px;
    font-size: 10px;
    padding: 5px;
  }
  .high {
    width: 97px;
    height: 20px;
    background: #EDC1C1;;
    border-radius: 4px;
    font-size: 10px;
    padding: 5px;
    text-align: center;
  }
  .incomplete {
    width: 97px;
    height: 20px;
    background: #BBBBBB;
    border-radius: 4px;
    font-size: 10px;
    padding: 5px;
    text-align: center;
  } 
  .low-percentile {
    width: 97px;
    height: 30px;
    background: #CCEEDA;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    text-align: center;
  }
  .medium-percentile {
    width: 97px;
    height: 30px;
    background: #FFECB4;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    text-align: center;
  }
  .high-percentile {
    width: 97px;
    height: 30px;
    background: #EDC1C1;;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    text-align: center;
  }
  .incomplete-percentile {
    width: 97px;
    height: 30px;
    background: #BBBBBB;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    text-align: center;
  }
  }
 

.template-tag{
  color: #ca4a04;
  font-size: 12px;
  font-weight: 500;
}


span.scenario-name-checkbox,span.scenario-des-checkbox {
  span > span {
    &:nth-child(1) {
      pointer-events: none;
    }
  }
}

.ant-tooltip.metric-scatter .ant-tooltip-inner {
  height: 130px;
  width: 400px;

  .scatter-title {
    color: #333333;
    font-size: 12px;
  }
}

.ant-drawer.complete-module-drawer {
  border: 1px solid #eaeaea;
  .submit-complete-btn {
    float: right;
  }

  .ant-drawer-content {
    .module-tip {
      font-size: 16px;
      color: #333;
      background-color: #EBE9E4;
      width: 100%;
      display: inline-block;
      padding: 13px 20px 11px 20px;
    }
    .select-scenario-wrapper {
      border: 1px solid #CCCCCC;
      margin-top: 20px;
      .scenario-table-header {
        display: flex;

        padding: 5px 0px;

        .scenario-col {
          flex: 1;
          font-size: 14px;
          color: #999;
        }
        .scenario-rate {
          // text-transform: uppercase;
          padding: 0px 10px;
        }
        .scenario-name {
          text-align: left;
          padding: 0px 10px;
          font-size: 18px;
          color: #333;
        }
      }
      .scenario-table-body {
        .scenario-table-row-wrapper {
          border-top: 1px solid #CCCCCC;

          &.checked {
            background-color: #F3F6F7;
          }
        }
        .scenario-table-row {
          display: flex;
          .ant-radio-wrapper{
            span{
              font-size: 22px;
              color: #333;
              font-weight: normal;
            }
          }
          .scenario-desc {
            padding-left: 24px;
            color:#999999;
            font-size: 14px;
            font-weight: normal;
          }
          .scenario-col {
            flex: 1;
            font-size: 26px;
            color: #414C4E;
            // text-align: center;
            font-weight: bold;
            padding: 5px 10px;
            border-bottom: 1px solid #eaeaea;
            &.scenario-name {
              display: flex;
              flex-direction: column;
              text-align: left;
              &.checked {
                border-bottom: 1px solid transparent;
              }
            }
            .status {
              display: block;
              font-size: 12px;
              &.HIGH {
                color: #C33232;
              }
              &.LOW{
                color: #00a947;
              }
              &.MEDIUM {
                color: #0084a9;
              }
            }
          }
        }
        .rationale-mark {
          padding: 10px;
          span {
            font-size: 16px;
            color:#666666;
          }

          .ant-input{
            color:#000000;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.ant-drawer-wrapper-body{
  .ant-drawer-title{
    font-size: 22px;
    color: #383A3C;
  }

  .ant-drawer-close{
    span{
      color: #000000;
      font-size: 20px;
    }
  }

  h5{
    font-size: 18px;
    color: #383A3C;
  }

  .ant-btn{
    span{
      color: #fff;
      font-size: 16px;
    }
  }

  // span{
  //   // font-size: 10px;
  //   color: #787878;
  // }

  .ant-input{
    border: 1px solid #D8D8D8;
  }

  .ant-drawer-footer{
    .ant-btn-text{
      float: left;
      span{
        color: #333333;
        font-size: 14px;
        // text-transform: uppercase;
      }
    }

    .create-update-btn, .submit-complete-btn {
      float: right;
      span{
        color: #fff;
        font-size: 14px;
        // text-transform: uppercase;
      }
    }
  }
}

.ant-btn.ant-btn-primary:focus:not(:disabled),
.ant-btn.ant-btn-primary:hover:not(:disabled){
  color: #fff !important;
  background: #ca4a04 !important;
}

.ant-btn.ant-btn-primary.submit-complete-btn span{
  color: unset;
}

.icon-placeholder {
  width: 28px;
  height: 28px;
  display: inline-block;
}
.history-container {
  align-items: center;
  .history_icon {
    display: inline-block;
    margin-top: 12px;
  }
}

.switch-container {
  margin-top: 20px;
  border: 1px solid #D8D8D8;
  padding: 16px 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;

  .ant-switch-checked {
    background-color: #ca4a04;
  }
  }
