.endpoint-container {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 25px !important;
    }
    .endpoint-header-layout {
        background-color: #DEDEDE;
        padding: 2% 6% 1%;

        .sub-heading {
            font-size: 14px;
        }
    }
    .endpoint-body-layout {
        .search-endpoint {
            border-radius: 20px;
            padding: 8px 15px;

            .ant-input {
                font-size: 14px;
            }
        }
        .search-endpoint-filters {
            border: 1px solid #d9d9d9; 
            border-radius: 20px;
            // float: right;
            padding: 5px;
            margin-left: 20px;
            width: 90%;
            text-align: center;
            background-color: #FFFFFF;
        } 
        .activities-container {
            background: #FFFFFF;

            .lists-container {
                max-height: calc(100% - 200px); 
                overflow-y: scroll;
            }

            .side-panel-heading {
                text-align: left;
                font-size: 22px; 
                color: #333333;
                padding: 10px 10px;
            }

            .sub-category {
                font-size: 16px; 
                color: #808080;
                padding-left: 5px;
            }
            .category {
                font-size: 16px; 
                color: #000000; 
            }

            .endpoint-btn-container { 
                position: absolute;
                bottom: 0;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 25px;

                .add-endpoint {
                    background: #CA4A04;
                    border-radius: 2px;
                    color: #FFFFFF;
                    // text-transform: uppercase;
                    font-size: 16px;
                    text-align: center;
                    display: flex;
                    justify-content: center; 
                    align-items: center;
                }
                .ant-btn[disabled], 
                .ant-btn[disabled]:hover, 
                .ant-btn[disabled]:focus, 
                .ant-btn[disabled]:active {
                  color: rgba(0, 0, 0, 0.25) !important;
                  background: #f5f5f5 !important;
                  border-color: #d9d9d9 !important;
                  text-shadow: none !important;
                  box-shadow: none !important;
                }
                .cancel-endpoint {
                    background: #FFFFFF;
                    border: 2px solid #CA4A04;
                    border-radius: 2px;
                    color: #CA4A04;
                    font-size: 16px;
                }
            }
        }
    }
}

 .ant-input{
   font-size: 18px;
   color: #333;
 }
 .ant-modal-footer{
   .ant-btn-primary{
     font-size: 16px;
   }
 }

.category-filters {
    background: white;
    padding: 10% 2%;
    box-shadow: 0px 0px 17px #00000029;

    .apply-filters {
        float: right;
        background: #CA4A04 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        text-align: center;
        border-radius: 2px;
    }

    .cancel-filters {
        text-align: center; 
        color: #CA4A04;  
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #CA4A04;
        border-radius: 2px;
    }
}

.ant-menu-vertical {
    border-right: none !important;
}

.endpoint-lib {
    .add-button {
        display: flex;
        justify-content: center;  
        align-items: center;
        text-align: center;
        background: #CA4A04;
        border-radius: 2px;
        color: #FFFFFF;
        font-size: 16px;
    }
}