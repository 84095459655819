.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

a.ant-typography,
.ant-typography a {
  color: #333;
  span {
    width: 100%;
  }
}

a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #000000d9;
}

.ant-table-cell {
  padding: 16px 5px !important;
}

.ant-table-cell > span {
  span.anticon-close {
    color: red;
    font-weight: bold;
  }
  span.anticon-check {
    color: green;
    font-weight: bold;
  }
}

span.popover-action {
  cursor: pointer;
}

span.anticon.anticon-close.ant-modal-close-icon {
  color: black;
}

.ant-btn-primary {
  background-color: #ca4a04;
  border-color: #ca4a04;
}

.ant-btn-primary.login__button {
  background-color: black;
  border-color: black;
  height: 40px;
}
.sub-criteria-wrapper {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #fbfbfb;

  .ant-input,
  .sub-row-non-editable {
    margin-right: 20px;
    color: #333;
  }

  .actions {
    margin-left: 5px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    .anticon {
      cursor: pointer;
    }
    .anticon.anticon-check {
      margin-right: 5px;
    }
  }

  .serial-number{
    padding-left: 45px;
    padding-right: 60px;
    color: #333;
  }
}
.sub-criteria-wrapper:hover, .sub-criteria-wrapper:focus {
  background-color: #e7e7e7;
}
.sub-row-non-editable {
  flex: 1;
  padding: 4px 11px;
  cursor: pointer;
}

.criteriaDetailModal.ant-modal-wrap{
   .ant-modal {
    width: 900px!important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-bottom: 1px solid #fff;
    border-radius: 10px;
  }
  .ant-modal-title{
    font-size: 18px;
    color: #000000;
  }
  .ant-modal-body{
    padding: 5px 28px 30px;
    .criterion-value {
      background-color: #F2F2F2;
      width: 100%;
      height: 95px;
      display: flex;
      padding: 20px;
      .item {
        flex: 1;
        .top {
          color:#656565;
          font-size: 14px;
        }
        .bottom {
          color: #000000;
          font-size: 16px;
          max-width: 260px;
        }
      }
    }
    .criterion-rate{
      line-height: 60px;
      span {
        font-size: 16px;
        color: #000000;
      }
      .frequency {
        margin-right: 30px;
      }
      .screen-failure{

      }
    }
    .criteria-table {
      .ant-table-column-sorters {
        color: #333333;
        font-weight: 600;
        .ant-table-column-sorter {
          color: #333333;
        }
      }
    }
    
  }
}
.ant-input{
  font-size: 18px;
  color: #333;
}
.ant-modal-footer{
  .ant-btn-primary{
    font-size: 16px;
  }
}

.criteria-ellipsis {
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: all;
}

.ant-form-item-control {
  position: relative;
  top: 11px;
  height: 54px;
  line-height: 54px;
  zoom: 1;
  .ant-form-item-control-input {
    height: 30px;
    line-height: 30px;
    .ant-form-item-control-input-content {
      display: inline-block;
      position: absolute;
      left: 0;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    div {
      display: inline-block;
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
  }

  .none-click {
  pointer-events: none
  }