@import '../../../styles/variables.scss';
.trial-summary-container{
    .trials-basic-info {
        .parallel-item {
            display: flex;
            width: 100%;
        }
        .trial-item{
            .oncology-selection { 
                display: inline-block !important; 
            }
            .horizontal-radio {
                display: inline-block;  
                margin-right: 10px;  
            }
            .ant-radio-button-wrapper {
                color: #d04a02;  
                border: 1px solid #d04a02 !important;
            }
            .ant-radio-button-wrapper.ant-radio-button-wrapper-checked  {  
                background-color: #d04a02;  
                color: white
              } 
            .trial-type-label {
                padding-right: 10px;
            }
            label {
                color: #000000;
                font-weight: 500;
            }
            .ant-select.ant-select-show-arrow{
                .ant-select-arrow .anticon{
                    color: $theme-color;
                }
            }
            .ant-select-selector .ant-select-selection-search input{
              width: 85%;
              overflow: 'hidden';
              text-overflow: 'ellipsis';
            }
            .ant-select-disabled .ant-select-selector {
                color: black !important;
            }
        }
    }
    .trial-summary-error{
        word-wrap: break-word;
        color: #d04a02
    }

    .required {
        font-size: 10px;
        color: #d04a02
    }
    .indication-warning{
        font-size: 12px;
        color:#333333;
        font-weight: 600;
        width: 250px;
        .asterisk{
          color:red;
        }
    }
}