.trial-detail-container {
    padding: 20px 30px;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px #00000029;
    z-index: 2;
    position: relative;
  
    .scenario-info {
      display: grid;
      margin-bottom: 20px;

        .go-back{
            color: #000000;
            font-size: 12px;
            cursor: pointer;

            .anticon-arrow-left{
                transform:translateY(-3px);
            }
        }

        .scenario-type{
            color: #333333;
            font-size: 28px;
        }

        .scenario-desc{
            color: #333333;
            font-size: 15px;
            padding-bottom: 10px;
            span span span {
              &:nth-child(1) {
                pointer-events: none;
              }
            }
        }
    }
  
    .info {
      .info-row {
        display: flex;
        .scenario-section {
          display: grid;
          flex: 1;
          padding-left: 20px;

          .section-title{
            color: #333333;
            font-size: 14px;
          }
          .section-value{
            color: #414C4E;
            font-size: 26px;
            font-weight: bold;
          }
          .section-level{
            font-size: 16px;
            font-weight: 600;

            &.LOW {
                color: #00a947;
            }
            &.HIGH {
                color: #c33232;
            }
            &.MEDIUM {
                color: #0084a9;
            }
          }
        }
      }
    }
}

.rationale-info{
    padding: 10px 0px;
    margin: 0px 30px;
    background: #F7F7F7;
    box-shadow: 0px 0px 6px #00000029;
    display: flex;

    &.hidde{
      display: none;
    }

    .title{
        font-size: 16px;
        color: #666666;
        // text-transform: capitalize;
        border-right: 1px solid #ccc;
        padding: 0px 20px;
        align-self: center;
    }
    .content{
        font-size: 14px;
        padding: 0px 20px;
        font: normal normal normal 14px/21px; letter-spacing: 0px;
        color: #000000;
        span span span {
          &:nth-child(1) {
            pointer-events: none;
          }
        }
    }
}

.scenario-details-tab{
  padding: 20px 0px;

  .ant-tabs-tab-btn{
    color: #999999;
    font-size: 16px;
    // text-transform: uppercase;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #CA4A04;
    font-size: 16px;
    // text-transform: uppercase;
  }

  .ant-tabs-ink-bar{
    background: #CA4A04;
  }

  .ant-tabs-tab:hover, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active{
    color: #CA4A04;
  }
  .ant-tabs-nav::before{
    border-bottom: unset !important;
  }
  .ant-tabs-nav{
    margin: 0 0 0 0 !important;
  }

  .criteria-container::-webkit-scrollbar {
    display: none;
  }
  .criteria-container{
    overflow: scroll;
    border: 1px solid #CCCCCC;

    .criteria-inner{
      // width: calc(100%);
      min-width: 2000px;
      height:400px;
      // overflow-y:scroll;

      .criteria-type{
        background-color: #282F34;
        color: #FFFFFF;
        font-size: 18px;
        padding: 20px 38px;
      }

      .criteria-header{
        font-size: 14px;
        color:#333;
        font-weight: bold;
        padding: 8px 0 8px 38px;
        border-bottom: 1px solid #CCCCCC;
        .ant-row .ant-col:nth-child(2) {
          // margin-left: -25px;
        }
        .ant-row .ant-col:nth-child(3) {
          // margin-left: -2px;
        }
        .ant-row .ant-col:nth-child(4) {
          padding-left: 20px;
        }
        .ant-row .ant-col:nth-child(5) {
          padding-left: 35px;
        }
        .ant-row .ant-col:nth-child(6) {
          padding-left: 48px;
        }
        .ant-row .ant-col:nth-child(7) {
          padding-left: 55px;
        }
        .ant-row .ant-col:nth-child(8) {
          padding-left: 65px;
        }
      }

      .criteria-table{
        .ant-collapse{
          border: unset;
        }
        .ant-collapse-icon-position-left>.ant-collapse-item>.ant-collapse-header{
          padding-left: 40px;
          background-color: #DEDEDE;
        }
        span.anticon.anticon-caret-right.ant-collapse-arrow{
          position: absolute;
          top: 5.0005px;
          left: 16px;
          padding: 12px 16px 12px 0;
          font-size: 12px;
        }
        .trial-panelHeader{
          .header-section{
              font-weight: 500;
              color: #333;
          }
        }
      }
      .none-click{
        pointer-events:none;
      }
    }
  }

  .none-click{
    pointer-events:none;
  }
}
  