.export-container {
    box-shadow: 0 1px 0 0 #31393F33;
    .tip1-desc{
        color:#999;
        font-size: 12px;
        display: block;
        padding-bottom: 10px;
    }
    .tab-title{
        font-size: 18px;
        color: #000000;
        font-weight: bold;
    }
    .breadcrumbs {
        padding: 5px 10px 12px 12px;
        .menu {
            font-size: '25px';
        }
    }
    .breadcrumbs-container {
        padding: 1px 6px
    }
    .ant-dropdown-button {
        float: left !important;
    }
}
