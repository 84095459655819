@import "../../styles/variables.scss";
.SimialarHistoryModal {
  .ant-modal-content {
  width: 60% !important;
  float: right;
}
}
.trial-portfolio-container {  
  .ant-breadcrumb {
    padding: 20px 0 30px 100px;
    // background-color: #dedede;
    color: #999999;
    a {
      text-decoration: none;
    }
    .homepage {
      cursor: pointer;
      color: $theme-color;
      .anticon.anticon-home {
        margin-right: 8px;
        transform: translateY(-4px);
      }
    }
    .ant-breadcrumb-link{
      color: #999999;
    }
  }
  .list-top {
    display: flex;
    margin-bottom: 30px;
    padding: 0 100px;
    align-items: flex-end;
    .count {
      flex: 1;
      font-size: 32px;
      color: #333333;
      .num{
        font-size: 28px;
      }
    }
    .filter-selector {
      display: flex;

      .selector-item {
        margin-right: 20px;
        label {
          color: #787878;
          font-size: 10px;
          font-weight: bold;
        }
        .ant-select{
          color: #354052;
        }
      }
    }
  }
  .upper {
    padding: 20px 100px 0px 100px;
    
    .title {
      font-size: 32px;
      color: #000000;
      margin-bottom: 0;
    }
    .sub-title {
      font-size: 26px;
      color: #000000;
    }

    .small-trial {
      color: $theme-color;
      font-size: 12px;
      font-weight: 600;
    }

    .action-part {
      display: flex;
      margin-top: 30px;
      .status-filter {
        display: flex;
        flex: 1;
        .item {
          margin-right: 50px;
          cursor: pointer;
          text-align: center;
          padding: 10px;
          &.active {
            border-bottom: 4px solid $theme-color;
            color: $theme-color;
            .number,
            .status {
              color: $theme-color;
            }
          }
        }

        span {
          display: block;
          color: #000000;
        }
        .number {
          font-size: 42px;
          font-weight: bold;
        }
        .status {
          font-size: 14px;
          color: #000000;
        }
      }
    }
  }
  .search-container {
    padding: 0 100px;
    height: 47px;
  }
  .full-search {
    border-radius: 20px;
    padding: 8px 15px;
  }

  .Filter {
    right: -10px;
    top: 46px;
  }

  .full-search-icon {
    position: absolute;
    background-image: url(../../assets/icon_Filter_Outline_Black.png); 
    background-repeat: no-repeat;
    background-position: right;
    padding: 21px;
    background-size: 25%;
    z-index: 9;
    
  }
}
.add-new-trial {
  display: flex;
  align-items: flex-end;
  border-left: 1px solid #d9d9d9;
  margin-top: 20px;
  padding-left: 20px;
}
.add-btn {
  background-color: $theme-color;
  color: #ffffff;
  font-size: 16px;
  padding: 20px 20px;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .anticon.anticon-plus-circle {
    margin-right: 10px;
  }
}

.trial-details-wrapper {
  padding: 0 100px 0px 100px;
}

.trial-item {
  margin-bottom: 10px;

  label {
    color: #787878;
    font-size: 14px;
    display: block;
    margin-bottom: 2px;
  }
  .ant-input{
    color: #333333;
    font-size: 14px;
  }
}

.parallel-item {
  display: flex;
  justify-content: space-between;
  .trial-item:first-child {
    margin-right: 15px;
  }
}

.ant-modal-wrap {
  .ant-modal {
    width: 60% !important;
  }
}

.ant-modal-header {
  display: flex;
}

.action-timeline {
  display: flex;
  align-items: center;
  margin-left: 50px;
  .step {
    font-size: 12px;
    color: #d8d8d8;
    .anticon {
      font-size: 20px;
    }
    .num {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 18px;
      text-align: center;
      background-color: #d8d8d8;
      color: #ffffff;
      border: 1px solid #d8d8d8;
      border-radius: 10px;
      margin-right: 5px;
      &.step1 {
        color: #d04a02;
        border-color: #d04a02;
        background-color: #ffffff;
      }
    }
    .num.active {
      color: #d04a02;
      border-color: #d04a02;
      background-color: #ffffff;
    }
    .name.active {
      color: #333333;
    }
  }

  .line {
    width: 40px;
    border: 0.5px solid #d8d8d8;
    height: 0.5px;
    margin: 0 5px;
  }
}

.trial-modal-footer {
  .anticon {
    font-size: 25px;
    color: #d04a02;
    cursor: pointer;
  }
  .step2-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .step1-footer {
    text-align: right;
  }
}

.ant-drawer-footer {
  .action-btn-footer {
    display: flex;
    justify-content: space-between;
    .left-action {
      display: flex;
      .go-prev-step {
        font-size: 14px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $theme-color;
        text-transform: uppercase;
        font-weight: 500;

        .anticon-left{
          color:#CA4A04;
        }
      }
    }
    button span{
      font-weight: 500;
    }
  }
}
.current-step {
  color: $theme-color;
}

.new-trial-wrapper {
  .navigation-bar {
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 10px;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .navigation-title {
    color: #333333;
    font-weight: 600;
    margin-bottom: 30px;
    display: block;
  }
  .main-content {
    flex: 1;
    padding-left: 20px;
    padding-top: 15px;
    .title {
      color: #383A3C;
      font-weight: 600;
      margin-bottom: 30px;
      display: block;
      font-size: 18px;
    }
  }

  .main-content.similar-table{
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -24px;
    padding-top: 0px;
    padding-left: 0px;
  }
}
.navigation-bar {
  .ant-steps-label-vertical .ant-steps-item-content {
    margin-top: 2px;
  }
  .ant-steps-item .ant-steps-item-title {
    font-size: 14px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    color: #CF4500;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    color: #999999;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: $theme-color;
  }
  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: $theme-color;
  }
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
      background: #fff;
      border: 1px solid #CA4A04;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
    background: #D8D8D8;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    color: #333333;
  }
  .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail{
    margin: 0 0 0 0px;
  }
  .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon{
    margin-left:unset;
  }
  .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail{
    width: calc(100% + 5px);
  }
  .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content{
    width: 100%;
    text-align:left;
  }
}




