.footer {
  background-color: #282f34;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  padding: 10px 20px;
  .left {
    flex: 1;
  }
  .right {
    display: flex;
    span {
      padding: 0 20px;
      cursor: pointer;
    }
  }
}
.ant-layout-footer {
  z-index: 9;
}
.FooterDisclaimersModal {
  .ant-modal-content {
  position: absolute;
  left: 8%;
  top:-100px !important;
  }
  .ant-modal-close-x {
  display: none;
  }
  .message {
    white-space: pre-wrap;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 300px;
    min-height: 200px;
    min-width: 800px;
    margin-bottom: -15px;
}
}
