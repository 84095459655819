@import "../../styles/variables.scss";

.main {
  .header {
    background-color: white;
    height: 141px;
    padding-left: 10%;
    align-content: center;

    & .header-back {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      & .arrow-icon {
        color: black;
      }

      & .back-btn {
        color: black;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        margin-left: 1%;
      }
    }

    & .header-title {
      font-size: 28px;
      font-weight: 600;
      color: black;
    }
  }

  & .card-div {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    padding: 0 10%;

    & .card-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(39, 45, 55, 1);
    }
    & .card-title-status {
      font-size: 16px;
      font-weight: 700;
      color: rgba(39, 45, 55, 1);
    }

    & .protocol-version-title {
      font-size: 15px;
      font-weight: 500;
      color: rgba(39, 45, 55, 1);
    }
    & .protocol-version-desc {
      font-size: 14px;
      font-weight: 500;
      color: rgba(95, 109, 126, 1);
    }

    & .protocol {
      border-radius: 10px;
      border-color: rgba(234, 235, 240, 1);
      border: 1px;

      & .create-text {
        font-size: 14px;
        font-weight: 600;
        padding: 15px;
        color: rgba(67, 126, 247, 1);
      }
    }

    .card {
      background-color: white;
      border-radius: 10px;
      border-color: rgba(234, 235, 240, 1);
      border: 1px;
      padding: 18px 0;

      & .card-title-div {
        display: flex;
        justify-content: space-between;
        padding: 0 18px;
      }

      & .filter-row {
        padding: 18px 0px 18px 18px;
        align-content: center;

        & .dropdown-icon {
            padding-right: 8px;
            margin-bottom: 4px;
          }
      }

      & .card-container {
        padding: 18px 18px;

        & .card-items {
          padding: 14px;
          border-radius: 10px;
          box-shadow: rgba(16, 24, 40, 0.04);
          height: auto;
          align-items: center;

          & .card-item-title {
            font-size: 18px;
            font-weight: 600;
            color: rgba(39, 45, 55, 1);
          }

          & .card-item-status {
            font-size: 14px;
            font-weight: 500;
            color: rgba(95, 109, 126, 1);
            display: flex;
            justify-content: space-between;
          }
          & .card-item-icon {
            color: rgba(0, 0, 0, 1);
          }
        }
      }

      & .card-date-div {
        font-size: 14px;
        font-weight: 500;

        & .protocol-section-card {
          border-radius: 10px;
          border-color: black;
          box-shadow: rgba(16, 24, 40, 0.04);
          border: 1px;
        }
      }
    }
  }

  & .divider {
    margin: 0;
  }

  & .ant-card-body {
    padding: 0px;
  }

  & .protocol-version-card {
    display: flex;
    padding: 16px;
    justify-content: space-between;
  }

  & .protocol-version-card-bg {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    background-color: rgba(238, 255, 241, 1);
  }

  & .protocol-version-card:hover {
    background-color: rgba(238, 255, 241, 1);
  }

  & .status-dropdown {
    border-radius: 6px;
    border: 1px solid rgba(208, 215, 222, 1);
    cursor: pointer;
  }

  & .import-dropdown {
    background-color: rgba(33, 98, 247, 1);
    border-radius: 5px;
    margin-top: 22px;
    color: white;
    cursor: pointer;
  }

  & .status-dropdown-text {
    padding: 4px 0 4px 8px;
    justify-content: center;
  }
}
