@import "../../styles/variables.scss";

.searchbox.ant-dropdown {
    .ant-dropdown-menu{
        // width: 308px;
        height: 300px!important;
        overflow: auto!important;

        .itemLine {
            width: 250px;

            .itemTitle {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .matched-item {
                font-weight: 700;
            }
        }

    }
}

.scenario-container{
    .insight-box-wrapper {
        background-color: #F8F8F8;
        width: 100%;
        padding: 0 10px;
    }
    .insight-box {
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        margin: 10px 0;
        padding: 10px;
        .head {
            color:#999999;
            font-size: 12px;
            font-weight: 500;
        }
        .content {
            color: #404042;
            margin: 20px 0;
            font-size: 14px;

        }
        .button-area {
            color:#0084A9;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            padding-right: 8px;
            .button-left, .button-right {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    //max-width: 1440px;
    .left_process-container {
        background-color: #282f34;
        padding: 10px 0;
        color: #fff;
    }

    left: 0;
    right: 0;
    background-color: #fff;
    min-height: calc(100vh - 88px);

    .none-click{
        pointer-events: none;
    }

    .ant-btn-primary:focus{
        color: #fff;
        background-color: #ca4a04;
        border-color: #ca4a04;
    }

    .action-title,  .action-title:hover{
        font-weight: 600;
        cursor: pointer;
        text-align: center;
    }

    .process-container{
        background-color: #282f34;
        padding: 10px 0;
        color:#fff;
        .ant-steps .ant-steps-item.ant-steps-item-wait .ant-steps-item-content .ant-steps-item-title{
            color: #999999;
        }

        .item-translate{
            font-size: 15px;
            padding: 0px 10px;

            .name{
                font-size: 16px;
                font-weight: bold;
            }
        }

        .scenario-header{
            border-left: 1.5px solid #c4bfbf;
            border-right: 1.5px solid #c4bfbf;
        }
        .center{
            align-self: center;
        }
    }

    Button{
        float: right;

        .ant-btn-primary{
            margin-right: 50px;
        }
    }

    Button.view-btn {
        background-color: #fff;
        color: #ca4a04;
        border-color: #ca4a04;
    }

    .step-btn{
        margin-right:10px;
    }

    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #FFFEFD;
        border: 2px solid #E68700;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #fff;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: #E68700;
        font-weight: 600;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: #fff;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #E68700;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: #fff;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #E68700;
    }

    .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        margin: 0 0 0 0px;
    }
    .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        margin-left: 0px;
    }
    .ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
        width: calc(100% - 10px);
    }
    .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
        width: 100%;
    }
    .ant-steps-label-vertical .ant-steps-item-content {
        text-align: left;
        margin-top: 0px;
    }

    .ie-container{
        border-top: 1px solid #dedede;
        min-height: calc(100vh - 156px);
        // max-width: 1260px;
    }

    .ie-container{
        .export-container,  .export-container:hover{
            // position: absolute;
            width:100%;
            align-items: center;
            margin: auto;
            .export-row {
                align-items: center;
            }

            .export-navigations {
                .homepage {
                    cursor: pointer;
                    color: $theme-color;
                }
            }
            
            .finish-btn-group {
                float: right;
                background-color: #FFFFFF;
                padding-top: 10px;
            }

            .ant-btn-group.ant-dropdown-button{
                border: 1.5px solid #CA4A04;
                margin-top: 7px;
                float: right;

                .ant-btn.ant-btn-default{
                    border-top:none;
                    border-left:none;
                    border-bottom:none;
                    border-right: unset;
                    color:#CA4A04;
                }

                .ant-btn.ant-btn-default.ant-btn-icon-only{
                    border-left:1px solid #CA4A04;
                }
            }
        }

        .tab-container{
            width: 100%;
            // min-height: calc(100vh - 156px);

            .side-toolbar{
                position: relative;
                z-index: 999;
                color:white;
                background-color: #F8F8F8;
                min-height: calc(100vh - 98px);
                width: 50px;
                align-items: center;
                border: 1px solid #DDD;

                .panel-label{
                    display:none;
                }
                .icon{
                    display:inline-block;
                }

                .anticon-arrow-right{
                    font-weight: 900;
                    font-size: 16px;
                    transform: translateY(-1px);
                }

                .action-wrapper {
                    position: absolute;
                    right: -11px;
                    top: 9px;
                    width: 12px;
                    height: 20px;
                    border: 1px solid #DDD;
                    border-radius:0 20px 20px 0;
                    background-color: #F8F8F8;;
                    border-left: 1px solid #F8F8F8;;
                  }
                .action-title {
                    position: absolute;
                    right: -9px;
                    top: 7px;
                    font-size: 14px;
                    padding-left: 8px;
                    height: 50px;
                    font-weight: 600;
                    cursor: pointer;
                    text-align: center;
                    color:#CA4A04;
                    span {
                        font-size: 13px;
                    }
                  }

            }

            .side-toolbar.hidden{
                display : none;
            }

            .side-toolbar:hover, .side-toolbar:focus{
                .panel-label{
                    display:inline-block;
                }
            }

            .tip1-desc{
                color:#999;
                font-size: 15px;
                display: block;
                padding-bottom: 10px;
            }

            .ant-tabs-tab {
                font-size: 16px;
                color: #676767;
            }
            .ant-tabs-tab:hover {
                color: #CA4A04;
            }
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #CA4A04;
            }
            .ant-tabs-ink-bar {
                background: #CA4A04;
            }
            .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
                height: 3px;
            }
            .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
                margin: unset;
            }

            .left-section{
                width: calc(100% - 30px);
                margin: 0 15px;

                .item-option{
                    color:#aba9a9;
                    font-size: 14px;
                }
            }

            .item-header{
                align-items: center;
                padding: 5px 15px;
                border-bottom: 1px solid #ddd;

                span{
                    font-size:16px;
                    font-weight: bold;
                    color: #333;
                }

                .right-icon{
                    float: right;
                    min-width:30px;
                }

                span.anticon.anticon-close.right-icon, span.anticon.anticon-history.right-icon{
                    transform: translateY(6px);
                }
                .action-wrapper {
                    position: absolute;
                    right: -2px;
                    top: 9px;
                    width: 12px;
                    height: 20px;
                    border: 1px solid #DDDDDD;
                    border-radius: 20px 0 0 20px;
                    background-color: #fff;
                    border-right: 1px solid #fff;
                  }
                .action-title {
                    position: absolute;
                    right: -4px;
                    top: 2px;
                    font-size: 14px;
                    padding-left: 8px;
                    height: 50px;
                    font-weight: 600;
                    cursor: pointer;
                    text-align: center;
                    color:#CA4A04;
                    span {
                        font-size: 13px;
                    }
                  }
            }

            .item{
                align-items: center;
                height:30px;
                span{
                    font-size:16px;
                    font-weight: 500;
                }

                .right-icon{
                    float: right;
                    min-width:30px;
                }
            }

            .freqSection {
                background-color: #fff;
                border: 1px solid #f3f3f3;
                padding: 5px 10px 15px 5px;
                .title, .content{
                    span{
                        font-size: 14px;
                    }
                    .right-icon{
                        font-size: 12px;
                        color: #999;
                    }
                }
                .title{
                    color:#666;
                }
                .content{
                    color:#333;
                }


                .right-icon{
                    float: right;
                    min-width:30px;
                    transform: translateY(6px);
                }
            }

            .searchSection {
                background-color: #fff;
                border: 1px solid #f3f3f3;
                margin-bottom: 10px;
                // padding: 10px;
                .title, .content{
                    span{
                        font-size: 14px;
                    }
                }


            }

            .content-outer{
                overflow-y:hidden;
                // height: calc(100vh - 431px);
                .sectionPanel {
                    // overflow-y: scroll;
                }
            }

            .content-sidebar{
                // remove the scroll bar of library
                // height: calc(100vh - 426px)!important;
            }

            .content-outer::-webkit-scrollbar {
                display: none;
            }

            .content-over{
                width: calc(100%);
                // remove the scroll bar of library
                // height: calc(100vh - 426px)!important;
                // overflow-y:scroll;
                box-sizing: content-box;

                .section-header{
                    font-weight: 500;
                    font-size: 16px;
                    color: #333;

                    .count-span{
                        margin-left: 5px;
                        padding: 0px 3px;
                        background-color: #333;
                        color: #fff;
                        font-size: 12px;
                    }

                    .collapse-icon{
                        .anticon-plus, .anticon-minus{
                            color: #999;
                        }
                    }
                }

                .eventLib {
                    margin-bottom: 10px;


                }

                .eventLib.ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header{
                    padding: 6px 16px;
                }

                .eventLib.ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
                    padding: 0px;
                }

                // New requirement to recover criteria option
                .library.box{
                    background-color: #fff;
                    font-weight:bold;
                    bottom: 10px;
                    font-size: 15px;
                    color:#000;

                    .select-option-wrapper {
                        .select-option {
                            display: flex;
                            font-weight: normal;
                            font-size: 14px;
                            border-bottom: 1px solid #DDD;
                            width: 100%;
                            padding: 10px 15px;
                            color: #4E4E4E;
                            align-items: center;
                            .select-text {
                                width: 100%;
                                order: 1;
                            }

                            .select-button {
                                width: 75px;
                                order: 2;
                                text-align: right;
                                align-self: center;
                            }

                            .select-button.add {
                                color: #CA4A04;
                            }
                            .select-button.remove {
                                color: #999;
                            }

                            .selected {
                                color:#3193E5;
                                border-color: #3193E5;
                            }
                        }

                        .select-option:hover {
                            cursor: pointer;
                        }
                    }

                    .anticon-check{
                        transform: translateY(-3px);
                    }
                }

                .library.box.lastOne{
                    margin-bottom: unset;
                }
            }

            .updateTrial{
                width: 100%;
                text-align: center;
                height: 50px;

                .update-btn{
                    // margin-top: 20px;
                    border: 1.5px solid #CF4500;
                    color: #CA4A04;
                    width: 90%;
                    margin: 10px 5% 40px 5%;
                    background-color: #fff;
                    align-items: center;
                }
            }
            .main-content-right{
                border-left: 1px solid #f8f8f8;
                .right-content-scroll {
                    height: 550px;
                    overflow-y: scroll;
                }
            }

            .option-item {
                border-right: 1px solid #ddd;
            }
            .collapse-section-wrapper{
               border-bottom: 1px solid #31393F33;

                .ant-collapse > .ant-collapse-item{
                    border-bottom: none;
                }
                .ant-collapse{
                    border-right: 1px solid transparent;
                }

            }

            .trial-panelHeader{
                .bar-desc {
                    font-size: 18px;
                    color: #333;
                    display:inline-block;
                    min-width:75px; 
                    margin-right:10px;
                }

                .item-desc {
                    min-width: 120px;
                    display:inline-block;
                    span{
                        font-size:12px;
                        color: #333;
                    }

                    .bar-item{
                        display:inline-block;
                        content: "";
                        width: 7px;
                        height: 7px;
                        border-radius: 5px;
                        margin-right:5px;
                    }

                    .item1{
                        background-color: #E53500;
                    }

                    .item2{
                        background-color: #F27A26;
                    }

                    .item3{
                        background-color: #F5924D;
                    }

                    .item4{
                        background-color: #FBD0B3;
                    }
                }

                .header-section{
                    font-weight: 500;
                    color: #333;
                }
            }

            .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header {
                padding-left: 40px;
            }

            .ant-collapse{
                // border-top: 1px solid #fafafa;ant-collapse
            }

            .ant-collapse > .ant-collapse-item {
                // border-bottom: 1px solid #d9d9d9;
                background-color: #fff;
            }

            .sectionPanel > .ant-collapse > .ant-collapse-item {
                background-color: #e7e7e7;
            }

            span.anticon.anticon-caret-right.ant-collapse-arrow {
                position: absolute;
                top: 5.0005px;
                left: 16px;
                padding: 12px 16px;
                padding-left: 0;
                font-size: 12px;
            }

            .ant-collapse-content > .ant-collapse-content-box{
                div.chart-container {
                    display:inline-block;
                    width:48%;
                    .label {
                        max-width: 300px; 
                    }
                    .detail {
                        color:#CA4A04;
                        font-weight: 500;
                        font-size: 14px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .chart-title {
                        font-size: 16px;
                        margin: 0;
                    }
                    .chart-description {
                        font-size: 16px;
                        color: #E53500;
                        margin: 0;
                    }
                }
                div.chart-container.box {
                    border-left: 1px solid #ddd;
                    padding-left: 26px;
                }
                div.chart-container .center-box{
                    display: flex; 
                    .copy-width {
                        width: 70%;
                        margin-top: 20px;
                    }
                    .pie-width {
                        width: 30%;
                    }
                    
                }
            }
            .impact-summary-wrapper{
                margin-top: 20px;
                box-shadow: 0px 1px 3px #31393F33;
                // border-top: 1px solid #f8f8f8;
                // border-bottom: 1px solid #f8f8f8;
            }
            .impact-summary{

                font-size: 18px;
                color: #333;
            }

            .impact-summary, .criteria-list{
                width: 100%;
                display:inline-block;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                padding:5px 20px;
                // position: fixed;

                bUtton{
                    float:right;
                }

                .list-columns{
                    display: flex;
                    padding: 5px 0 5px 0px;

                    div {
                        flex: 1 1;
                        color: #333;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }

            .collapse-container{
                // border: 1px solid #ddd;
            }

            .collapse-inner{
                // width: calc(100% + 20px);
                width:100%;
                // height: calc(100vh - 605px);
                overflow-y:scroll;
                box-sizing: content-box;
                .sectionPanel {
                    // overflow-y: scroll;
                    // height: calc(100vh - 637px);
                }
                .right-icon{
                    float: right;
                    min-width:30px;
                    padding-right:30px;
                }
            }

            .collapse-inner.taller{
                .sectionPanel {
                    // overflow-y: scroll;
                    // height: calc(100vh - 462px);
                }
                // height:400px;
                // height: calc(100vh - 431px);
            }

            .collapse-inner.taller.collapsed{
                // height:230px
                // height: calc(100vh - 605px);
                .sectionPanel {
                    // overflow-y: scroll;
                    // height: calc(100vh - 437px);
                }
            }

            .enrollment-right-section{
                // height: calc(100vh - 203px);
                // overflow-y: scroll;
                padding-bottom: 38px;
                box-sizing: border-box;
                span.chart-title {
                    font-size: 16px;
                    color: #000;
                    font-weight: bold;
                    margin-top: 10px;
                    padding: 10px 0;
                }

                .enroll-tab{
                    margin-top: 10px;

                    .chart-tab{
                        box-shadow: 0px 1px 3px #31393F33;

                        .tab-item{
                            padding:10px;
                            color: #333;

                            .sub-tab-title{
                                font-size:42px;
                                font-weight:bold;
                            }

                            .tab-desc{
                                font-weight: normal;
                                font-size: 16px;
                            }
                        }

                        .tab-item.chart{
                            padding:10px 10px 0px 10px;
                        }

                        .anticon-caret-right{
                            transform: translateY(5px);
                        }
                    }

                    .chart-tab.active{
                        border: 2px solid #CA4A04;
                        box-shadow: 0px 0px 11px #31393F33;
                    }
                }

                .result-chart{
                    margin-top:20px;
                    margin-bottom: 20px;
                    box-shadow: 0px 1px 5px #31393F33;

                    .title {
                        position: relative;
                        z-index: 999;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 24px;
                        margin-left: 5px;
                        margin-right:5px;
                        color: #676767;
                        overflow: hidden;
                        background-color: #fff;
                        .caption {
                            position: relative;
                            left: 3%;
                            display: inline-block;
                            .line {
                                position: absolute;
                                top: 11px;
                                width: 6000px;
                                overflow: hidden;
                                height: 1px;
                                background-color: #ccc;
                            }
                            .line-l {
                                right: 100%;
                                margin-right: 5px;
                            }
                            .line-r {
                                left: 100%;
                                margin-left: 5px;
                            }
                        }
                    }


                }
            }
        }
    }

    .ant-slider-rail{
        background-color: #efa882;
    }

    .ant-slider-track{
        background-color: #ca4a04;
    }
    .ant-slider-handle.ant-tooltip-open, .ant-slider-handle:focus {
        border-color: #ca4a04;
    }
    .ant-slider:hover .ant-slider-track {
        background-color: #ca4a04;
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: #efa882;
    }
    .ant-slider-handle{
        border: solid 2px #efa882;
    }
    .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging{
        border-color: #ca4a04;
    }

    .legend-wrapper-father {
        position: relative;
    }
    .my-legend-wrapper{
        position: absolute;
        z-index: 80;
        right: -5px;
        top: 0px;
        width: 150px;
        height: 95px;
        overflow: scroll;
        .custom-legend{
          cursor: pointer;
          .my_legend{
            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 1px;
            margin-right: 4px;
          }
          .my_legend_text{
            color: #666666;
            font-size: 10px;
            font-style:normal;
          }
          .active {
            color:#CA4A04;

          }
        }

      }
}

button.ant-btn.ant-btn-text, button.ant-btn.ant-btn-text:focus, button.ant-btn.ant-btn-text:hover {
    font-weight: 500;
    color: #000000d9;
    background-color: #fff;
    border: unset;
}

.action-title {
  font-size: 14px;
  display: flex;
  padding-left: 8px;
  margin-top: 5px;

  .anticon-left{
    margin-top: 5px;
  }
}

.ant-steps-item-title {
font-size: 12px;
}
.item-option span {
    font-size: 14px;
    color: #999;
}

.left-section .head-row {
.frequency {
  color: #333;
  font-size: 10px;
}
.label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.anticon-edit{
    color: #999;
}
.anticon-edit.active{
    color: #CA4A04;
}
}

.tab-title{
    font-size: 24px;
    color: #000000;
    font-weight: bold;
}

.detail-table-wrapper.ant-popover {
    .ant-popover-inner-content {
        padding: 0px;
    }
    .ant-table-thead .ant-table-cell {
        font-weight: 600;
        background-color: #fff;
    }
    .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
        padding: 5px 15px;
    }
}