@import "../../../styles/variables.scss";
.team-members-container {
  min-height: 400px;
  .add-box {
    display: flex;
    align-items: flex-end;
    div {
      margin-right: 10px;
      flex: 1;
    }
    label{
      font-size: 16px;
      color:#000000;
      font-weight: 600;
    }
  }

  .user-list-wrapper {
    .user-list-title {
      display: block;
      padding: 20px 0 10px 0;
      border-bottom: 1px solid #eeeeee;
      font-weight: 600;
      color: #000000;
      font-size: 16px;
      span.count.count-icon.active-count {
        background-color: #717171;
      }
    }
    .user-list {
      .user-item {
        display: flex;
        padding: 8px 2px 8px 0;
        border-bottom: 1px solid #eeeeee;
        align-items: center;
        .id {
          color: #999999;
          font-size: 12px;
        }
        .icon {
          display: inline-block;
          background-color: #1162B4;
          border-radius: 16px;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          margin-right: 10px;
          font-size: 14px;
          text-transform: uppercase;
        }
        .name{
          color: #000000;
          font-size: 16px;
        }

        div {
          flex: 1;
        }

        .anticon.anticon-close{
          color: #999999;
          font-size: 16px;
        }
        
      }

      .role{
        color:#383A3C;
        font-size: 14px;
      }
    }
  }
}
